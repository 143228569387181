import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsUser } from '../actions/notificationActions';

export const HomeScreen = () => {

  const dispatch = useDispatch();

  const userSignin = useSelector(state => state.userSignin)
  const {
    userInfo
} = userSignin;


  useEffect(() => {
    if (userInfo) {
      dispatch(getNotificationsUser());
    }
    
  }, [dispatch, userInfo])
  return (
    <>
      <style>{`body {background-image: none !important}`}</style>
      <div id="home-container">
        <div className='row text-center text-white mw-100'>
          <div className='col-12 p-0 mb-3'>
            <img src='/assets/media/logos/Grupo_187.png' alt='Logo de Compañía de María' className='home-logo' />
          </div>
          <div className='col-12 mt-2 d-flex justify-content-center p-0'>
            <p className='fs-sm-1 home-paragraph'>
              Le damos la bienvenida a nuestra plataforma. En nuestra continua intención de mejora ponemos a su disposición esta aplicación donde podrás:
            </p>
          </div>
          <div className='col-12 mt-2 d-flex justify-content-center p-0'>
              <ul className='home-list'>
                <li>ADQUIRIR MATERIAL ESCOLAR</li>
                <li>PAGAR ACTIVIDADES EXTRAESCOLARES</li>
                <li>PAGAR COMEDOR</li>
              </ul>
          </div>
        </div>
      </div>
    </>

  )
}
