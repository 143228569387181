import React from 'react'
import { Link } from 'react-router-dom';

export const SubscriptionProduct = (props) => {
    const {subscriptionProduct} = props;
    return (
        <div key={subscriptionProduct?._id} className="card">
            <Link to={`/subscriptionProduct/${subscriptionProduct?._id}`} className="text-center">
                <img
                className="img-fluid"
                style={{
                    height: 220,
                  }}
                src={subscriptionProduct?.image ? subscriptionProduct.image : "/Grupo_183.png"}
                alt={subscriptionProduct?.name}
                />
            </Link>
            <div className="card-body">
                <Link to={`/subscriptionProduct/${subscriptionProduct?._id}`}>
                <h2>{subscriptionProduct?.name}</h2>
                </Link>
            </div>
        </div>
    )
}
