import React from 'react'
import { Link } from 'react-router-dom';

export const ExtracurricularActivity = (props) => {
    const {extracurricularActivity} = props;
    return (
        <div key={extracurricularActivity?._id} className="card">
            <Link to={`/extracurricularActivity/${extracurricularActivity?._id}`} className="text-center">
                <img
                className="img-fluid"
                style={{
                    height: 220,
                  }}
                src={extracurricularActivity?.image ? extracurricularActivity.image : "/Grupo_183.png"}
                alt={extracurricularActivity?.name}
                />
            </Link>
            <div className="card-body">
                <Link to={`/extracurricularActivity/${extracurricularActivity?._id}`}>
                <h2>{extracurricularActivity?.name}</h2>
                </Link>
            </div>
        </div>
    )
}
