import React from 'react'
import { Link } from 'react-router-dom';

export const Product = (props) => {
    const {product} = props;
    return (
        <div key={product._id} className="card">
            <figure style={{height: 220}} className='text-center'>
                <Link to={`/product/${product._id}`}>
                    <img
                    className="img-fluid"
                    style={{
                        maxHeight: 220,
                    }}
                    src={product.image ? product.image : "/Grupo_183.png"}
                    alt={product.name}
                    />
                </Link>
            </figure>
            <div className="card-body">
                <Link to={`/product/${product._id}`}>
                <h2>{product.name}</h2>
                </Link>                 
                <div className="price">{product?.price?.toFixed(2).replace('.', ',')} €</div>
            </div>
        </div>
    )
}
