export const paycometGetDataReducer = (state = { loading: false, paycometData: null, error: null }, action) => {
    switch (action.type) {
        case 'PAYCOMET_GET_DATA_REQUEST':
            return { loading: true };
        case 'PAYCOMET_GET_DATA_SUCCESS':
            return { loading: false, paycometData: action.payload, error: null };
        case 'PAYCOMET_GET_DATA_FAIL':
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const paycometSaveReducer = (state = { loading: false, result: null, error: null }, action) => {
    switch (action.type) {
        case 'PAYCOMET_SAVE_DATA_REQUEST':
            return { loading: true };
        case 'PAYCOMET_SAVE_DATA_SUCCESS':
            return { loading: false, result: action.payload, error: null };
        case 'PAYCOMET_SAVE_DATA_FAIL':
            return { loading: false, error: action.payload };
        case 'PAYCOMET_SAVE_DATA_RESET':
            return { loading: false, result: null, error: null };
        default:
            return state;
    }
}