import React, { useEffect, useState } from 'react'
import { ToolBar } from '../components/ToolBar';
import { LoadingBox } from '../components/LoadingBox';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select"
import Swal from 'sweetalert2';
import { listCourses } from '../actions/courseActions';
import { listUsers } from '../actions/userActions';
import {sendNotificationClass, sendNotificationUser, sendNotificationAll, getNotificationsUser, sendNotificationExtracurricularActivity} from '../actions/notificationActions'
import { getAvailableSplittedExtracurricularActivities } from '../actions/extracurricularActivityActions';

export const PushNotificationScreeen = () => {

    const [notificationTitle, setNotificationTitle] = useState('')
    const [notificationBody, setNotificationBody] = useState('')
    const [sendingNotification, setsendingNotification] = useState(false)

    const [userOptions, setUserOptions] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])

    const [courseOptions, setCourseOptions] = useState([])
    const [selectedCourseOptions, setSelectedCourseOptions] = useState([])

    const [extracurricularActivityOptions, setExtracurricularActivityOptions] = useState([])
    const [selectedExtracurricularActivityOptions, setSelectedExtracurricularActivityOptions] = useState([])

    const userList = useSelector(state => state.userList);
    const {users} = userList
    const courseList = useSelector((state) => state.courseList);
    const { courses } = courseList;

    const {extracurricularActivities} = useSelector((state) => state.extracurricularActivityList);

    const dispatch = useDispatch();

    const pushNotificationHandler = async(e) => {
        e.preventDefault();
        if (selectedUsers.length > 0) {
            setsendingNotification(true)
            selectedUsers.forEach((user, index) => {
                const pushNnotification = {
                    notification: {
                        notification: {
                            title: notificationTitle,
                            body : notificationBody,
                            icon : '/assets/media/logos/Grupo_185.jpg'
                        }
                    },
                    user: user.value
                }

                const notification = {
                    user: user.value,
                    title: notificationTitle,
                    description: notificationBody,
                    selectedUsers: selectedUsers
                }
                dispatch(sendNotificationUser(pushNnotification, notification))
                    if (index === selectedUsers.length - 1) {
                        setsendingNotification(false)
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Notificaciones enviadas correctamente',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
            })
        } 
        if (selectedCourseOptions.length > 0) {
            setsendingNotification(true)
            selectedCourseOptions.forEach((course, index) => {
                const pushNotification = {
                    notification: {
                        notification: {
                            title: notificationTitle,
                            body : notificationBody,
                            icon : '/assets/media/logos/Grupo_185.jpg'
                        }
                    },
                    class: course.value,
                    selectedUsers: selectedUsers
                }
                const notification = {
                    class: course.value,
                    title: notificationTitle,
                    description: notificationBody,
                    selectedUsers: selectedUsers
                }
                dispatch(sendNotificationClass(pushNotification, notification))
                    if (index === selectedCourseOptions.length - 1) {
                        setsendingNotification(false)
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Notificaciones enviadas correctamente',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
            })
        }
        if (selectedExtracurricularActivityOptions.length > 0) {
            setsendingNotification(true)
            selectedExtracurricularActivityOptions.forEach((extracurricularActivity, index) => {
                const pushNotification = {
                    notification: {
                        notification: {
                            title: notificationTitle,
                            body : notificationBody,
                            icon : '/assets/media/logos/Grupo_185.jpg'
                        }
                    },
                    extracurricularActivity: extracurricularActivity.extracurricularActivity,
                    selectedUsers: selectedUsers
                }
                const notification = {
                    extracurricularActivity: extracurricularActivity.extracurricularActivity,
                    title: notificationTitle,
                    description: notificationBody,
                    selectedUsers: selectedUsers
                }
                dispatch(sendNotificationExtracurricularActivity(pushNotification, notification))
                    if (index === selectedExtracurricularActivityOptions.length - 1) {
                        setsendingNotification(false)
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Notificaciones enviadas correctamente',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
            })
        }
        if (selectedUsers.length === 0 && selectedCourseOptions.length === 0 && selectedExtracurricularActivityOptions.length === 0) {
            const pushNotification = {
                notification: {
                    title: notificationTitle,
                    body : notificationBody,
                    icon : '/assets/media/logos/Grupo_185.jpg'
                }
            }
            const notification = {
                title: notificationTitle,
                description: notificationBody
            }
            dispatch(sendNotificationAll(pushNotification, notification))
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Notificaciones enviadas correctamente',
                    showConfirmButton: false,
                    timer: 1500
                })
        }

        setNotificationTitle('');
        setNotificationBody('');
        setSelectedUsers([]);
        setSelectedCourseOptions([]);
    }

    const handleChange = (selectedOptions) => {
        setSelectedUsers(selectedOptions)
    }

    const handleCoursesChange = (selectedOptions) => {
        setSelectedCourseOptions(selectedOptions)
    }

    const handleExtracurricularActivitiesChange = (selectedOptions) => {
        setSelectedExtracurricularActivityOptions(selectedOptions)
    }

    useEffect(()=>{
        dispatch(listUsers())
        dispatch(listCourses())
        dispatch(getNotificationsUser());
        dispatch(getAvailableSplittedExtracurricularActivities())
    }, [dispatch])

    useEffect(()=>{
        let options = []
        users?.forEach(user => {
            if (options.find(option => option.value === user?._id) === undefined) {
                options.push({ value: user._id, label: user.name })
            }
        });
        setUserOptions(options)
    }, [users])

    useEffect(() => {
        if (courses) {
            setCourseOptions([])
            courses.map((course) => (
                setCourseOptions((courseOptions) => [...courseOptions, { value: course._id, label: course.name }])
            ))
        }
    }, [courses])

    useEffect(() => {
        if (extracurricularActivities) {
            setExtracurricularActivityOptions([])
            extracurricularActivities.map((extracurricularActivity) => (
                setExtracurricularActivityOptions((courseOptions) => [...courseOptions, { value: `${extracurricularActivity._id}${extracurricularActivity.fee}`, label: extracurricularActivity.name, extracurricularActivity: extracurricularActivity }])            
            ))
        }
    }, [extracurricularActivities])

    return (
        <>
        <ToolBar titulo={"Notificaciones Push"} hitos={[
            {titulo: "Inicio", enlace: "/" },
            {titulo: "Notificaciones Push", enlace: "#" }]}/>
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">        
            <form autoComplete='off' className="form w-100" noValidate="novalidate" id="kt_sign_in_form" onSubmit={pushNotificationHandler}>
                <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">Crear notificación push</h1>                
                </div>
                <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">Título</label>
                    <input type="text" id="notificacionTitle" name="notificacionTitle" value={notificationTitle} onChange={ e => setNotificationTitle(e.target.value)} placeholder="Título de la notificación" className="form-control form-control-lg form-control-solid" required/>                
                </div>
                <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">Descripción</label>
                    <input type="text" id="notificacionBody" name="notificacionBody" value={notificationBody} onChange={ e => setNotificationBody(e.target.value)} placeholder="Descripción de la notificación" className="form-control form-control-lg form-control-solid" required/>                
                </div>

                <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">Destinatarios</label>
                    <Select isMulti isClearable options={userOptions}  onChange={handleChange} value={selectedUsers}  placeholder="Escoja los destinatarios" />
                    <span className="text-muted fs-7">Si no selecciona ningún usuario se enviará a todos</span>
                </div>

                <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">Cursos</label>
                    <Select isMulti isClearable options={courseOptions}  onChange={handleCoursesChange} value={selectedCourseOptions}  placeholder="Escoja los destinatarios" />
                    <span className="text-muted fs-7">Si no selecciona ningún curso se enviará a todos</span>
                </div>

                <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">Actividades Extraescolares</label>
                    <Select isMulti isClearable options={extracurricularActivityOptions}  onChange={handleExtracurricularActivitiesChange} value={selectedExtracurricularActivityOptions}  placeholder="Escoja los destinatarios" />
                    <span className="text-muted fs-7">Si no selecciona ninguna actividad extraescolar se enviará a todos</span>
                </div>

                <div className="text-center">
                    <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">     
                        {sendingNotification ? (<LoadingBox variant={"primary"}/>)
                        :
                        (<span className="indicator-label">Enviar notificación</span>)}
                    </button>
                </div>
            </form>
        </div>
        </>
        
    )
}

