import React from 'react'
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";

export const CustomDataTable = (props) => {
  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };
  
  const paginationOptions = { rowsPerPageText: 'Elementos por página:', rangeSeparatorText: 'de', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }

  const {columns, data, onRowClicked = null} = props;
  const tableData = {
    columns: columns,
    data: data
  };

  return (
    <>
    <DataTableExtensions exportHeaders={true} filterPlaceholder="Buscar..." {...tableData}>
      <DataTable        
        noDataComponent="No se han encontrado resultados"
        labelRowsPerPage="Elementos por página"
        defaultSortField="id"
        defaultSortAsc={false}
        pagination        
        customStyles={customStyles}  
        paginationRowsPerPageOptions={[10, 20, 50, 100, 500]}
        paginationComponentOptions={paginationOptions}
        onRowClicked={onRowClicked}
      />
    </DataTableExtensions>
    </>
  )
}
