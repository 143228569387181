import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { detailsUser, updateUser } from '../actions/userActions';
import { AlertMessage } from '../components/AlertMessage'
import { LoadingBox } from '../components/LoadingBox'
import { ToolBar } from '../components/ToolBar';
import { USER_UPDATE_RESET } from '../constants/userConstants';
import Select from "react-select"
import { listStudents } from '../actions/studentActions';
import Swal from 'sweetalert2';
import { getNotificationsUser } from '../actions/notificationActions';

export const UserEditScreen = () => {
    const navigate = useNavigate();
    const params = useParams();
    const userId = params.id;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [isConsultant, setIsConsultant] = useState(false);
    const [isTeacher, setIsTeacher] = useState(false);
    const [isStopbullying, setIsStopbullying] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [phone, setPhone] = useState('');
    const [dni, setDni] = useState('');
    const [password, setPassword] = useState('')
    const [image, setImage] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [address, setAddress] = useState('');

    const [passwordShow, setPasswordShow] = useState(false)
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)

    const userDetails = useSelector(state => state.userDetails);
    const { loading, error, user } = userDetails;

    const userUpdate = useSelector(state => state.userUpdate);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = userUpdate;

    const studentList = useSelector(state => state.studentList);
    const { loading: loadingStudents, error: errorStudents, students } = studentList;

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [errorUpload, setErrorUpload] = useState('');

    const dispatch = useDispatch();

    // Select de Usuarios 
    // const options = []
    const [options, setOptions] = useState([])

    const selectedUsers = [];
    const [selectedOptions, setSelectedOptions] = useState([])


    useEffect(() => {
        if (students && !loadingStudents) {
            setOptions([])
            students.map((student) => (
                setOptions(options => [...options, { value: student._id, label: student.name }])
            ))
        }

    }, [students, loadingStudents])

    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: USER_UPDATE_RESET });
            dispatch(detailsUser(userId))
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Padre editado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            if (!user) {
                dispatch(detailsUser(userId))
            }
            else {
                setName(user.name)
                setEmail(user.email)
                setIsAdmin(user.isAdmin)
                setIsConsultant(user.isConsultant)
                setIsTeacher(user.isTeacher)
                setIsStopbullying(user.isStopbullying)
                setPhone(user.phone)
                setDni(user.dni)
                setCity(user.userAddress?.city)
                setState(user.userAddress?.state)
                setPostalCode(user.userAddress?.postalCode)
                setAddress(user.userAddress?.address)
                setImage(user.image)
                setIsActive(user.active !== false ? true : false)
            }
    }
        dispatch(listStudents())
        dispatch(getNotificationsUser());

    }, [dispatch, user, userId, navigate, successUpdate])


    useEffect(() => {
        if (user) {
            let arrayValores = [];
            user.userChildren.map((child) => (
                arrayValores.push(options.find(option => option.value === child._id))
            ))
            setSelectedOptions(arrayValores);

        }
    }, [user, options])

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        const bodyFormData = new FormData();
        bodyFormData.append('image', file);
        setLoadingUpload(true);
        try {
            const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })
            setImage(data);
            setLoadingUpload(false)
        } catch (error) {
            setErrorUpload(error.message);
            setLoadingUpload(false);
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: 'Las contraseñas no coinciden'
            })
        } else {

            // Añadir objetos de hijos al padre            
            for (const user of students) {
                for (const selectedOption of selectedOptions) {
                    if (user._id === selectedOption.value) {
                        selectedUsers.push(user)
                    }
                }
            }

            dispatch(updateUser({
                _id: userId,
                name,
                email,
                isAdmin,
                isConsultant,
                isTeacher,
                isStopbullying,
                password,
                phone,
                userChildren: selectedUsers,
                address,
                city,
                state,
                postalCode,
                dni,
                image,
                active: isActive
            }));
            setPassword('')
            setConfirmPassword('')
        }
    }

    const handleChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions)
    }

    console.log(isActive);

    return (
        <>
            <ToolBar titulo={"Editar usuario"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Usuarios", enlace: "/userList" },
                { titulo: "Editar usuario", enlace: "#" }]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <div>
                        {loadingUpdate && <LoadingBox variant={"primary"} />}
                        {errorUpdate && <AlertMessage variant={"danger"} message={errorUpdate} />}
                    </div>
                    {
                        loading ? <LoadingBox variant={"primary"} /> :
                            error ? <AlertMessage variant={"danger"} message={error} /> :
                                <>
                                    <div className="card mb-5 mb-xl-10">
                                        <div className="card-body pt-9 pb-0">
                                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                                <div className="me-7 mb-4">
                                                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                        {user.image ?
                                                            <img src={user.image}></img>
                                                        :
                                                            <img src="/assets/media/avatars/blank.png" alt="Avatar" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                        <div className="d-flex flex-column">
                                                            <div className="d-flex align-items-center mb-2">
                                                                <h1 className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{name}</h1>
                                                            </div>
                                                            {user.userChildren.length > 0 &&
                                                                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                                                    <div className="text-hover-primary fs-3 fw-bolder me-1">Hijos</div>
                                                                    {user.userChildren.map((hijo) => (
                                                                        <div className="col-12" key={hijo.name}>{hijo.name}</div>
                                                                    ))}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="fv-row mb-2">
                                                <label htmlFor="imageFile" className="form-label">Actualizar imagen de perfil</label>
                                                <input className="form-control" required={false} type="file" id="imageFile" onChange={uploadFileHandler}></input>
                                                {loadingUpload && <LoadingBox variant={"primary"} />}
                                                {errorUpload && <div>{errorUpload}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mb-5 mb-xl-10">
                                        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">Datos del usuario</h3>
                                            </div>
                                        </div>
                                        <div id="kt_account_settings_profile_details" className="collapse show">

                                            <form autoComplete='off' id="kt_account_profile_details_form" className="form" onSubmit={submitHandler}>
                                                <div className="card-body border-top p-9">
                                                    <h3 className="fw-bolder mx-0 my-5">Datos generales</h3>

                                                    <div className="row mb-6">
                                                        <label className="col-lg-4 col-form-label required fw-bold fs-6">Nombre</label>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row">
                                                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="name" type="text" placeholder="Introduce el nombre" value={name || ''} onChange={(e) => setName(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-6">
                                                        <label className="col-lg-4 col-form-label required fw-bold fs-6">Correo electrónico</label>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row">
                                                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="email" type="email" placeholder="Introduce el email" value={email || ''} onChange={(e) => setEmail(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-6">
                                                        <label className="col-lg-4 col-form-label required fw-bold fs-6">DNI</label>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row">
                                                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="dni" type="text" placeholder="Introduce el dni" value={dni || ''} onChange={(e) => setDni(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-6">
                                                        <label className="col-lg-4 col-form-label required fw-bold fs-6">Teléfono</label>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row">
                                                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="phone" type="tel" placeholder="Introduce el número de teléfono" value={phone || ''} onChange={(e) => setPhone(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-6">
                                                        <label className="col-lg-4 col-form-label required fw-bold fs-6">Nueva contraseña</label>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row position-relative">
                                                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" type={passwordShow ? 'text' : 'password'} id="password" value={password || ''} placeholder="Contraseña" onChange={e => setPassword(e.target.value)} />
                                                                    <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={() => {setPasswordShow(!passwordShow)}}>
                                                                        {
                                                                        passwordShow 
                                                                        ? <i className="bi bi-eye-slash fs-2"></i>
                                                                        : <i className="bi bi-eye fs-2"></i>
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-6">
                                                        <label className="col-lg-4 col-form-label required fw-bold fs-6">Confirmar contraseña</label>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row position-relative">
                                                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" type={confirmPasswordShow ? 'text' : 'password'} id="confirmPassword" value={confirmPassword || ''} placeholder="Confirmar contraseña" onChange={e => setConfirmPassword(e.target.value)} />
                                                                    <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={() => {setConfirmPasswordShow(!confirmPasswordShow)}}>
                                                                        {
                                                                        confirmPasswordShow 
                                                                        ? <i className="bi bi-eye-slash fs-2"></i>
                                                                        : <i className="bi bi-eye fs-2"></i>
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-6">
                                                        <label className="col-lg-4 col-form-label required fw-bold fs-6">Hijos</label>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row">

                                                                    {loadingStudents ? (<LoadingBox variant={"primary"} />) :
                                                                        errorStudents ? (<AlertMessage variant={"danger"} message={errorStudents} />) : (
                                                                            <Select isMulti isClearable options={options} onChange={handleChange} value={selectedOptions || ''} placeholder="Escoja los hijos del usuario" />
                                                                        )}
                                                                    <div className="text-muted fs-7">Escoja uno a uno los padres del alumno. Tambien puede buscarlos escribiendo parte del nombre.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h3 className="fw-bolder mx-0 mb-5 mt-10">Datos de dirección</h3>

                                                    <div className="row mb-6">
                                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Dirección</label>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row">
                                                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="address" type="text" placeholder="Introduce tu dirección" value={address || ''} onChange={(e) => setAddress(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-6">
                                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Localidad</label>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row">
                                                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="city" type="text" placeholder="Introduce tu localidad" value={city || ''} onChange={(e) => setCity(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-6">
                                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Provincia</label>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row">
                                                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="state" type="text" placeholder="Introduce tu provincia" value={state || ''} onChange={(e) => setState(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-10">
                                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Código postal</label>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12 fv-row">
                                                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="postalCode" type="text" placeholder="Introduce tu CP" value={postalCode || ''} onChange={(e) => setPostalCode(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-6 mt-10">
                                                        <div className='form-group mt-3'>
                                                            <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                <input className='form-check-input' type='checkbox' checked={isAdmin} onChange={(e) => {setIsAdmin(e.target.checked); if (e.target.checked) {setIsConsultant(false)}}} />
                                                                <span className='form-check-label fw-bold text-gray-400'>¿Es admin?</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-6 mt-10">
                                                        <div className='form-group mt-3'>
															<label className='form-check form-switch form-check-custom form-check-solid'>
																<input className='form-check-input' type='checkbox' checked={isTeacher} onChange={(e) => setIsTeacher(e.target.checked)} />
																<span className='form-check-label fw-bold text-gray-400'>¿Es profesor?</span>
															</label>
														</div>
                                                    </div>
                                                    <div className="row mb-6 mt-10">
														<div className='form-group mt-3'>
															<label className='form-check form-switch form-check-custom form-check-solid'>
																<input className='form-check-input' type='checkbox' checked={isStopbullying} onChange={(e) => setIsStopbullying(e.target.checked)} />
																<span className='form-check-label fw-bold text-gray-400'>¿Pertenece a 'No al Bullying'?</span>
															</label>
														</div>
                                                    </div>
                                                    <div className="row mb-6">
                                                        <div className='form-group mt-3'>
                                                            <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                <input className='form-check-input' type='checkbox' checked={isConsultant} onChange={(e) => {setIsConsultant(e.target.checked); if (e.target.checked) {setIsAdmin(false)}}} />
                                                                <span className='form-check-label fw-bold text-gray-400'>¿Es consultivo?</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-6 mt-10">
														<div className='form-group mt-3'>
															<label className='form-check form-switch form-check-custom form-check-solid'>
																<input className='form-check-input' type='checkbox' checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
																<span className='form-check-label fw-bold text-gray-400'>Activo</span>
															</label>
														</div>
                                                    </div>
                                                </div>
                                                <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                    <Link to={'/userlist'} className="btn btn-light me-5">Cancelar</Link>
                                                    <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit">Actualizar usuario</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </>
                    }
                </div>
            </div>
        </>
    )
}
