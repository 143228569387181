import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom';
import { createCategory, listCategories } from '../actions/categoryActions';
import { createProduct } from '../actions/productActions';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import Select from "react-select"
import { listAttributes } from '../actions/attributeActions';
import { listCourses } from '../actions/courseActions';
import Swal from 'sweetalert2';
import { listStudents } from '../actions/studentActions';

export const CreateProductScreen = () => {

    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [sku, setSku] = useState('')
    const [price, setPrice] = useState('')
    const [iva, setIva] = useState(0);
    const [image, setImage] = useState('')
    const [countInStock, setCountInStock] = useState(null)
    const [maxUnits, setMaxUnits] = useState(null)
    const [brand, setBrand] = useState('')
    const [description, setDescription] = useState('')
    const [active, setActive] = useState(false)
    const [alternativePayment, setAlternativePayment] = useState(false)
    const [billable, setBillable] = useState(false)
    const [oneTimePurchase, setOneTimePurchase] = useState(false)
    const [catalogMode, setCatalogMode] = useState(false)
    const [requiresAuthorization, setRequiresAuthorization] = useState(false)
    const [authorizationText, setAuthorizationText] = useState(null)

    const [categoryName, setCategoryName] = useState('')
    const [categoryDescription, setCategoryDescription] = useState('')
    const [categoryShow, setCategoryShow] = useState(true)

    const [currentCategories, setCategories] = useState([])


    const categorieList = useSelector((state) => state.categorieList);
    const { loading: loadingCategory, error: errorCategory, categories } = categorieList;

    const attributeList = useSelector((state) => state.attributeList);
    const { attributes } = attributeList;

    const createdProduct = useSelector((state => state.productCreate))

    const categoryCreated = useSelector(state => state.categoryCreate)

    const courseList = useSelector((state) => state.courseList);
    const { loading: loadingCourses, error: errorCourses, courses } = courseList;

    const { loading: loadingStudents, error: errorStudents, students } = useSelector(state => state.studentList)
    
    const [options, setOptions] = useState([])
    const selectedCategories = [];
    const [selectedOptions, setSelectedOptions] = useState([])
    const [attributesOptions, setAttributesOptions] = useState([])
    const [selectedAttribute, setSelectedAttribute] = useState([])
    const [selectedAttributeValues, setSelectedAttributeValues] = useState([])

    const [coursesOptions, setCoursesOptions] = useState([])
    const [selectedCourses, setSelectedCourses] = useState([])

    const [studentsOptions, setStudentsOptions] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([])

    const dispatch = useDispatch();

    const closeModalBtn = useRef();

    useEffect(() => {
        if (categories) {
            setCategories(categories)
        }
    }, [categories])

    useEffect(()=>{
        let categoriesToPut = []
        currentCategories.map((category) => (
            categoriesToPut.push({ value: category._id, label: category.name })                
        ))
        setOptions(categoriesToPut)
    }, [currentCategories])

    useEffect(() => {
        dispatch(listCategories());
        dispatch(listAttributes());
        dispatch(listCourses())
        dispatch(listStudents())
    }, [dispatch])

    useEffect(()=>{
        if (createdProduct?.product) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Producto creado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            navigate(`/product/${createdProduct.product._id}/edit`)
        }
    }, [createdProduct, navigate])

    //Cuando obtenemos los atributos
    useEffect(()=>{
        if (attributes) {
            let optionsToUse = [{value: null, label: 'Sin atributo'}]
            attributes.forEach(attribute => {
                optionsToUse.push({value: attribute._id, label: attribute.name})
            });
            setAttributesOptions(optionsToUse)
            setSelectedAttribute(optionsToUse[0])
        }
    }, [attributes])

    //Cuando la categoria se haya creado
    useEffect(()=>{
        if (categoryCreated?.category) {
            setCategories([...currentCategories, categoryCreated.category])
            setSelectedOptions([...selectedOptions, { value: categoryCreated.category._id, label: categoryCreated.category.name }])
            closeModalBtn.current.click()
            setCategoryName('')
            setCategoryDescription('')
        }
    }, [categoryCreated, options, selectedOptions, currentCategories])

    useEffect(()=>{
        if (selectedAttribute.value) {
            let attributeOptionSelected = attributes.find(attribute => attribute._id === selectedAttribute.value)
            let attributeValues = []
            attributeOptionSelected.values.forEach(val => {
                let attributeCourses = []
                val.courses?.forEach(c => {
                    attributeCourses.push(courses?.find(co => co._id === c)?.name)
                })
                attributeValues.push({label: val.value, date: val.date, selected: true, priceImpact: 0, stock: 0, maxUnits: null, courses: attributeCourses.join(', '), coursesIds: val.courses})
            });
            setSelectedAttributeValues(attributeValues)
        } else {
            setSelectedAttributeValues([])
        }
    }, [selectedAttribute, attributes, courses])

    //When courses are loaded
    useEffect(() => {
        if (courses) {
            let coursesOp = []
            courses.forEach(course => {
                coursesOp.push({value: course._id, label: course.name})
            })
            setCoursesOptions(coursesOp)
        }
    }, [courses])

    //When students are loaded
    useEffect(() => {
        if (students) {
            let studentsOp = []
            students.forEach(student => {
                studentsOp.push({value: student._id, label: student.name})
            })
            setStudentsOptions(studentsOp)
        }
    }, [students])


    const submitHandler = (e) => {
        e.preventDefault();
        if (name !== '') {
            if (description !== '') {
                if (price !== '') {
                    if (selectedOptions.length > 0) {
                        if (!requiresAuthorization || (authorizationText && authorizationText?.trim() !== '')) {
                            for (const category of currentCategories) {
                                for (const selectedOption of selectedOptions) {
                                    if (category._id === selectedOption?.value) {
                                        selectedCategories.push(category)
                                    }
                                }
                            }
                    
                            let productAttributtes = []
                            
                            if (selectedAttribute.value) {
                                selectedAttributeValues.forEach((val) => {
                                    if (val.selected) {
                                        productAttributtes.push({attribute: selectedAttribute.value, value: {value: val.label, date: val.date, courses: val.coursesIds}, priceImpact: val.priceImpact, stock: val.stock, maxUnits: val.maxUnits})
                                    }
                                })
                                    
                            }
                    
                            let coursesSelected = []
                    
                            for (const course of selectedCourses) {
                                coursesSelected.push(course.value)
                            }
    
                            let studentsSelected = []
                    
                            for (const student of selectedStudents) {
                                studentsSelected.push(student.value)
                            }
                            
                            dispatch(createProduct({
                                name,
                                sku,
                                price,
                                image,
                                category: selectedCategories,
                                brand,
                                countInStock: productAttributtes.length === 0 ? countInStock : null,
                                description,
                                active,
                                iva,
                                attributes: productAttributtes,
                                courses: coursesSelected,
                                students: studentsSelected,
                                alternativePayment: alternativePayment,
                                billable: billable,
                                catalogMode,
                                oneTimePurchase: oneTimePurchase,
                                maxUnits: productAttributtes.length === 0 ? maxUnits : null,
                                requiresAuthorization,
                                authorizationText: requiresAuthorization ? authorizationText : null
                            }))
                            
                        } else {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: 'Introduzca un texto para la autorización',
                                showConfirmButton: true,
                                confirmButtonText: 'De acuerdo'
                            })
                        }
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Introduzca al menos una categoría',
                            showConfirmButton: true,
                            confirmButtonText: 'De acuerdo'
                        })
                    }
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Introduzca un precio para el producto',
                        showConfirmButton: true,
                        confirmButtonText: 'De acuerdo'
                    })
                }
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Introduzca una descripción para el producto',
                    showConfirmButton: true,
                    confirmButtonText: 'De acuerdo'
                })
            }
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Introduzca un nombre al producto',
                showConfirmButton: true,
                confirmButtonText: 'De acuerdo'
            })
        }
    }

    //Submit de formulario de crear categoria
    const submitCategoryHandler = (e) => {
        e.preventDefault()
        dispatch(createCategory({
            name: categoryName,
            description: categoryDescription,
            show: categoryShow,
        }))
    }

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [errorUpload, setErrorUpload] = useState('');

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        const bodyFormData = new FormData();
        bodyFormData.append('image', file);
        setLoadingUpload(true);
        try {
            const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })
            setImage(data);
            setLoadingUpload(false)
        } catch (error) {
            setErrorUpload(error.message);
            setLoadingUpload(false);
        }
    }

    const handleChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions)
    }

    const handleAttributeChange = (selectedOptions) => {
        setSelectedAttribute(selectedOptions)
    }

    const handleAttrCheck = (index) => {
        let newAttrValues = selectedAttributeValues.map((val, ind) => {
            if (ind === index) {
                return {...val, selected: !val.selected}
            } else {
                return val
            }
        })
        setSelectedAttributeValues(newAttrValues)
    }

    const handleAttrImpact = (index, value) => {
        let newAttrValues = selectedAttributeValues.map((val, ind) => {
            if (ind === index) {
                return {...val, priceImpact: value}
            } else {
                return val
            }
        })
        setSelectedAttributeValues(newAttrValues)
    }

    const handleAttrMaxUnits = (index, value) => {
        let newAttrValues = selectedAttributeValues.map((val, ind) => {
            if (ind === index) {
                return {...val, maxUnits: value}
            } else {
                return val
            }
        })
        setSelectedAttributeValues(newAttrValues)
    }

    const handleAttrStock = (index, value) => {
        let newAttrValues = selectedAttributeValues.map((val, ind) => {
            if (ind === index) {
                return {...val, stock: value}
            } else {
                return val
            }
        })
        setSelectedAttributeValues(newAttrValues)
    }

    const handleCoursesChange = (selectedValues) => {
        setSelectedCourses(selectedValues)
    }

    const handleStudentsChange = (selectedValues) => {
        setSelectedStudents(selectedValues)
    }

    const handleAlternativePaymentChange = (e) => {
        setAlternativePayment(e.target.checked)
    }

    const handleBillableChange = (e) => {
        setBillable(e.target.checked)
    }

    const handleOneTimePurchaseChange = (e) => {
        setOneTimePurchase(e.target.checked)
    }


    return (
        <>
            <ToolBar titulo={"Crear producto"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Productos", enlace: "/productlist" },
                { titulo: name, enlace: "#" }]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <form autoComplete='off' id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" onSubmit={submitHandler}>
                        <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                            <div className="card card-flush py-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h2>Miniatura</h2>
                                    </div>
                                </div>
                                <div className="card-body text-center pt-0">
                                    <div className="image-input image-input-empty image-input-outline mb-3" data-kt-image-input="true">
                                        <div className="image-input-wrapper w-150px h-150px" style={{ backgroundImage: `url('${image}')`, backgroundPosition: 'center', backgroundSize: 'contain' }} />
                                        <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                            <i className="bi bi-x fs-2" />
                                        </span>
                                        <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                            <i className="bi bi-x fs-2" />
                                        </span>
                                    </div>
                                    <div className="text-muted fs-7">Establezca la imagen en miniatura del producto. Solo se aceptan archivos de imagen *.png, *.jpg y *.jpeg</div>
                                </div>
                            </div>
                            <div className="card card-flush py-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h2>Estado</h2>
                                    </div>
                                    <div className="card-toolbar">
                                        <div className={`rounded-circle ${active ? 'bg-success' : 'bg-danger'} w-15px h-15px`} id="kt_ecommerce_add_product_status" />
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <select className="form-select mb-2" value={active} onChange={(e) => setActive(e.target.value)}>
                                        <option value={true}>Activado</option>
                                        <option value={false}>Desactivado</option>
                                    </select>
                                    <div className="text-muted fs-7">Cambiar estado de producto.</div>
                                </div>
                            </div>
                            <div className="card card-flush py-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h2>Modo catálogo</h2>
                                    </div>
                                    <div className="card-toolbar">
                                        <div className={`rounded-circle ${catalogMode ? 'bg-success' : 'bg-danger'} w-15px h-15px`} />
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <select className="form-select mb-2" data-control="select2" data-hide-search="true" data-placeholder="Select an option" value={catalogMode} onChange={(e) => setCatalogMode(e.target.value)}>
                                        <option value={true}>Activado</option>
                                        <option value={false}>Desactivado</option>
                                    </select>
                                    <div className="text-muted fs-7">Cuando esté activado, no se podrá adquirir el producto.</div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general">
                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>General</h2>
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="mb-10 fv-row">
                                                    <label className="required form-label">Nombre del producto</label>
                                                    <input className="form-control mb-2" id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                    <div className="text-muted fs-7">Se requiere un nombre de producto que sea único.</div>
                                                </div>
                                                <div className="mb-10 fv-row">
                                                    <label className="form-label">SKU del producto</label>
                                                    <input className="form-control mb-2" id="sku" type="text" value={sku} onChange={(e) => setSku(e.target.value)} />
                                                    <div className="text-muted fs-7">Introduzca una la referencia del producto si lo tuviera</div>
                                                </div>
                                                <div className="mb-10 fv-row">
                                                    <label className="required form-label">Descripción del producto</label>
                                                    <textarea className="form-control mb-3" rows="5" data-kt-element="input" placeholder="Escribe una descripción" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                                </div>
                                                <div className="mb-10 fv-row">
                                                    <label className="required form-label">Categoría</label>
                                                    {loadingCategory ? (<LoadingBox variant={"primary"} />) :
                                                        errorCategory ? (<AlertMessage variant={"danger"} message={errorCategory} />) : (
                                                            <Select isMulti isClearable options={options}  onChange={handleChange} value={selectedOptions}  placeholder="Escoja la/s categorías del producto" />
                                                        )
                                                    }
                                                    <button type="button" className=" mt-5 btn btn-light-primary btn-sm mb-10" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                        <span className="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black"></rect>
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="black"></rect>
                                                            </svg>
                                                        </span>
                                                        Crear nueva categoría
                                                    </button>
                                                </div>
                                                {/* {
                                                    process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD && process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD !== '' &&
                                                        <div className="fv-row mb-10">
                                                            <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                <span className='form-check-label fw-bold me-4'>Pago alternativo</span>
                                                                <input className='form-check-input' type='checkbox' checked={alternativePayment} onChange={handleAlternativePaymentChange} />
                                                            </label>
                                                        </div>
                                                } */}
                                                <div className="fv-row">
                                                    <label className='form-check form-switch form-check-custom form-check-solid'>
                                                        <span className='form-check-label fw-bold me-4'>Facturable</span>
                                                        <input className='form-check-input' type='checkbox' checked={billable} onChange={handleBillableChange} />
                                                    </label>
                                                </div>
                                                <div className="fv-row">
                                                    <label className='form-check form-switch form-check-custom form-check-solid'>
                                                        <span className='form-check-label fw-bold me-4'>Producto de compra única</span>
                                                        <input className='form-check-input' type='checkbox' checked={oneTimePurchase} onChange={handleOneTimePurchaseChange} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card card-flush pt-4">
                                            <div className="card-header flex-row w-100">
                                                <div className="card-title flex-row w-100">
                                                    <div className='row w-100 align-items-center'>
                                                        <div className='col-md-2 col-6'>
                                                            <h2>Atributos</h2>
                                                        </div>
                                                        {/* <div className='col-md-4 col-6'>
                                                            <button type="button" className="btn btn-light-primary btn-sm">
                                                                <span className="svg-icon svg-icon-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black"></rect>
                                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black"></rect>
                                                                    </svg>
                                                                </span>
                                                                Añadir atributo
                                                            </button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="fv-row mb-2">
                                                {/* AQUI LOS ATRIBUTOS */}
                                                    <Select options={attributesOptions}  onChange={handleAttributeChange} value={selectedAttribute}  placeholder="Escoja la/s categorías del producto" />
                                                </div>
                                                <div className='fv-row row'>
                                                    {selectedAttributeValues.map((attr, ind) => (
                                                        <div key={ind} className='col-md-4 col-12 px-2 py-4'>
                                                            <div className='border p-4'>
                                                                <h6>{attr.label}{attr.courses ? <span className='ms-4 text-muted'>{attr.courses}</span> : <></>}</h6>
                                                                <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                    <input className='form-check-input' type='checkbox' checked={attr.selected} onChange={() => {handleAttrCheck(ind)}} />
                                                                    <span className='form-check-label fw-bold text-gray-400'>Usar</span>
                                                                </label>
                                                                <label className='mt-3'>Impacto en el precio</label>
                                                                <input type="number" min={0} step="any" className="form-control" value={attr.priceImpact} name="price-impact" onChange={(e) => {handleAttrImpact(ind, e.target.value)}} />
                                                                <label className='mt-3'>Stock</label>
                                                                <input type="number" min={0} className="form-control" value={attr.stock} name="stock" onChange={(e) => {handleAttrStock(ind, e.target.value)}} />
                                                                <label className='mt-3'>Máximas unidades / pedido</label>
                                                                <input type='number' min={1} step={1} className='form-control' value={attr.maxUnits || ''} name='maxUnits' onChange={(e) => {handleAttrMaxUnits(ind, e.target.value)}} />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card card-flush py-4">
                                            <div className="card-header flex-row w-100">
                                                <div className="card-title flex-row w-100">
                                                    <div className='row w-100 align-items-center'>
                                                        <div className='col-6'>
                                                            <h2>Cursos relacionados</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="fv-row mb-2">
                                                {
                                                    loadingCourses
                                                    ?
                                                        (<LoadingBox variant={"primary"} />)
                                                    :
                                                        errorCourses
                                                        ?
                                                            (<AlertMessage variant={"danger"} message={errorCourses} />)
                                                        :
                                                            (
                                                                <Select isMulti options={coursesOptions} onChange={handleCoursesChange} value={selectedCourses}  placeholder="Escoja los cursos del producto" />
                                                            )
                                                }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card card-flush py-4">
                                            <div className="card-header flex-row w-100">
                                                <div className="card-title flex-row w-100">
                                                    <div className='row w-100 align-items-center'>
                                                        <div className='col-6'>
                                                            <h2>Estudiantes relacionados</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="fv-row mb-2">
                                                {
                                                    loadingStudents
                                                    ?
                                                        (<LoadingBox variant={"primary"} />)
                                                    :
                                                        errorStudents
                                                        ?
                                                            (<AlertMessage variant={"danger"} message={errorStudents} />)
                                                        :
                                                            (
                                                                <Select isMulti options={studentsOptions} onChange={handleStudentsChange} value={selectedStudents}  placeholder="Escoja los alumnos del producto" />
                                                            )
                                                }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card card-flush py-4'>
                                            <div className='card-header flex-row w-100'>
                                                <div className='card-title flex-row w-100'>
                                                    <div className='row w-100 align-items-center'>
                                                        <div className='col-6'>
                                                            <h2>Autorización</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-body pt-0'>
                                                <div className='fv-row my-3'>
                                                    <label className='form-check form-switch form-check-custom form-check-solid'>
                                                        <span className='form-check-label fw-bold me-4'>Requiere autorización</span>
                                                        <input className='form-check-input' type='checkbox' checked={requiresAuthorization} onChange={(e) => setRequiresAuthorization(e.target.checked)} />
                                                    </label>
                                                </div>
                                                <div className='fv-row my-3'>
                                                    <label className='form-label'>Texto de autorización</label>
                                                    <textarea className='form-control' disabled={!requiresAuthorization} value={authorizationText || ''} onChange={(e) => setAuthorizationText(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Precio</h2>
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="mb-10 fv-row">
                                                    <label className="required form-label">Precio (IVA inc.)</label>
                                                    <div className='input-group mb-2'>
                                                        <input className="form-control mb-2" id="price" type="text" value={price} onChange={(e) => setPrice(e.target.value.replace(',', '.'))} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text" id="euro">€</span>
                                                        </div>
                                                    </div>
                                                    <div className="text-muted fs-7">Establecer el precio del producto, IVA incluido.</div>
                                                </div>
                                                <div className="mb-10 fv-row">
                                                    <label className="form-label">IVA</label>
                                                    <select className="form-control mb-2" id="iva" value={iva} onChange={(e) => setIva(e.target.value)}>
                                                        <option value={0}>Sin IVA</option>
                                                        <option value={4}>Súper reducido (4%)</option>
                                                        <option value={10}>Reducido (10%)</option>
                                                        <option value={21}>General (21%)</option>
                                                    </select>
                                                    <div className="text-muted fs-7">Establecer el IVA del producto.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {selectedAttribute.value === null ? 
                                            <div className="card card-flush py-4">
                                                <div className="card-header">
                                                    <div className="card-title">
                                                        <h2>Inventario</h2>
                                                    </div>
                                                </div>
                                                <div className="card-body pt-0">
                                                    <div className="mb-10 fv-row">
                                                        <label className="form-label">Cantidad</label>
                                                        <div className="d-flex gap-3">
                                                            <input className="form-control mb-2" id="countInStock" type="text" value={countInStock || ''} onChange={(e) => setCountInStock(e.target.value)} />
                                                        </div>
                                                        <div className="text-muted fs-7">Introduce la cantidad del producto.</div>
                                                    </div>
                                                    <div className="mb-10 fv-row">
                                                        <label className="form-label">Máximas unidades / pedido</label>
                                                        <div className="d-flex gap-3">
                                                            <input className="form-control mb-2" id="maxUnits" type="text" value={maxUnits || ''} onChange={(e) => setMaxUnits(e.target.value)} />
                                                        </div>
                                                        <div className="text-muted fs-7">Máximas unidades del producto por pedido.</div>
                                                    </div>
                                                </div>
                                            </div> 
                                        :
                                            <></>
                                        }
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Imagen</h2>
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="fv-row mb-2">
                                                    <label htmlFor="imageFile" className="form-label">Eligir la imagen del producto</label>
                                                    <input className="form-control" required={false} type="file" id="imageFile" onChange={uploadFileHandler}></input>
                                                    {loadingUpload && <LoadingBox variant={"primary"} />}
                                                    {errorUpload && <div>{errorUpload}</div>}
                                                </div>
                                                <div className="text-muted fs-7">Configure la galería multimedia del producto.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Link to="/productlist" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">Cancelar</Link>
                                <button type="submit" id="kt_ecommerce_add_product_submit" className="btn btn-primary">
                                    <span className="indicator-label">Guardar</span>
                                    <span className="indicator-progress">Espere por favor...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">Crear categoría</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                <form autoComplete='off' className="form w-100 row" id="kt_sign_in_form" onSubmit={submitCategoryHandler}>
                                    <div className="mb-10 fv-row">
                                        <label className="required form-label">Nombre de la categoría</label>
                                        <input className="form-control mb-2" id="name" type="text" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
                                        <div className="text-muted fs-7">Se requiere un nombre de categoría que sea único.</div>
                                    </div>
                                    <div className="mb-10 fv-row">
                                        <label className="required form-label">Descripción</label>
                                        <textarea className="form-control mb-3" rows="5" data-kt-element="input" placeholder="Escribe una descripción" value={categoryDescription} onChange={(e) => setCategoryDescription(e.target.value)}></textarea>
                                    </div>
                                    <div className="mb-10 fv-row">
                                        <label className="required form-label">Estado</label>
                                        <select className="form-select mb-2" data-control="select2" data-hide-search="true" data-placeholder="Select an option" id="kt_ecommerce_add_product_status_select" value={categoryShow} onChange={(e) => setCategoryShow(e.target.value)}>
                                            <option value={true}>Mostrada</option>
                                            <option value={false}>Oculta</option>
                                        </select>
                                        <div className="text-muted fs-7">Mostrar/Ocultar categoría.</div>
                                    </div>
                                    <div className="text-end">
                                        <button type="submit" id="kt_sign_in_submit" className="btn btn-primary me-1" disabled={categoryCreated.loading ? 'disabled' : ''}>
                                        {
                                            categoryCreated.loading ? (
                                                <span className="indicator-progress">Espere por favor...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            ) :
                                            (<span className="indicator-label">Crear</span>)
                                        }
                                        </button>
                                        <button ref={closeModalBtn} type="button" id="closeModal" className="btn btn-secondary ms-1" data-bs-dismiss="modal">Cerrar</button>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}