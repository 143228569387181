import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeOrderSaveCard, detailsOrder } from "../actions/orderActions";
import {listPaymentMethods} from "../actions/paymentMethodActions"
import { ORDER_CHANGE_SAVE_CARD_RESET } from "../constants/orderConstants";
import { getOrderPaymentLink, getOrderStatus, payOrder } from "../actions/paymentActions";
import { PAYMENT_CHALLENGE_URL_RESET, PAYMENT_ORDER_RESET, PAYMENT_STATUS_RESET } from "../constants/paymentConstants";

export function PayCometCheckOutForm({order, user}) {

	const [isProcessing, setIsProcessing] = useState(false);

	const [selectedCard, setSelectedCard] = useState("null")
	const [waitingForPayment, setWaitingForPayment] = useState(false)

	
    const [savePaymentMethod, setSavePaymentMethod] = useState(true)

	const {paymentMethods} = useSelector(state => state.paymentmethodList)

	const { loading: loadingPaymentCreate, error: errorpaymentCreate, payment } = useSelector(state => state.paymentCreate)

	const { status } = useSelector(state => state.paymentStatus)

	const {result} = useSelector(state => state.orderSaveCard)

	const dispatch = useDispatch()

	const handleSubmit = async (e) => {
		e.preventDefault();
		dispatch(payOrder(order._id, selectedCard))
		setIsProcessing(true)
		const intervalFn = () => {
            clearInterval(window.orderDirectPaymentStatus)
            setWaitingForPayment(false)
            dispatch({ type: 'PAYMENT_STATUS_RESET' })
        }

        window.orderDirectPaymentStatusIndex = 0

        window.orderDirectPaymentStatus = setInterval(() => {
            window.orderDirectPaymentStatusIndex++
            dispatch(getOrderStatus(order._id))
            if (window.orderDirectPaymentStatusIndex > 600) {
                intervalFn()
            }
        }, 1000)
	};

	const handleSaveOrderCard = (status) => {
		dispatch(changeOrderSaveCard(status, order._id))
	}

	const handleSelectCard = ({target}) => {
		setSelectedCard(target.value)
		if (target.value === "null" || target.value === null) {
			dispatch(getOrderPaymentLink(order._id))
		}
	}

	const handlePaymentLinkClick = () => {
        setWaitingForPayment(true)

        const intervalFn = () => {
            clearInterval(window.orderPaymentStatus)
            setWaitingForPayment(false)
            dispatch({ type: 'PAYMENT_STATUS_RESET' })
        }

        window.orderPaymentStatusIndex = 0

        window.orderPaymentStatus = setInterval(() => {
            window.orderPaymentStatusIndex++
            dispatch(getOrderStatus(order._id))
            if (window.orderPaymentStatusIndex > 600) {
                intervalFn()
            }
        }, 1000)
    }

	useEffect(() => {
		dispatch(listPaymentMethods())
	}, [dispatch])

	useEffect(() => {
        if (paymentMethods?.length > 0) {
			setSelectedCard(paymentMethods[0]?._id || 'null')
        } else {
			dispatch(getOrderPaymentLink(order._id))
		}
    }, [paymentMethods, dispatch])

	useEffect(() => {
		if (order) {
			setSavePaymentMethod(order.tokenize || false)
		}
	}, [order])

	useEffect(() => {
		if (result) {
			setSavePaymentMethod(result.status)
			dispatch({ type: ORDER_CHANGE_SAVE_CARD_RESET })
		}
	}, [result, dispatch])

	useEffect(() => {
		if (status?.isPaid) {
			clearInterval(window.orderPaymentStatus)
			clearInterval(window.orderDirectPaymentStatus)
			setWaitingForPayment(false)
			dispatch({ type: PAYMENT_STATUS_RESET })
			dispatch({ type: PAYMENT_CHALLENGE_URL_RESET })
			dispatch({ type: PAYMENT_ORDER_RESET })
			dispatch(detailsOrder(order._id))
		}
	}, [status, dispatch])

	return (
		<div>
			{
				paymentMethods?.length > 0 && 
				<div className='my-5'>
					<select className='form-select' name="card" value={selectedCard} onChange={handleSelectCard}>
						{
							paymentMethods.map((paymentMethod, ind) => (
								<option key={ind} value={paymentMethod._id}>{paymentMethod.name || paymentMethod.last4}</option>
							))
						}
						<option value="null">Otra</option>
					</select>
				</div>
			}
			{
				selectedCard === 'null' &&
				<>
					<div className="my-5">
						<label className='form-check form-switch form-check-custom form-check-solid'>
							<input className='form-check-input' type='checkbox' checked={savePaymentMethod} onChange={e => handleSaveOrderCard(e.target.checked)} />
							<span className='form-check-label fw-bold me-4'>Guardar tarjeta</span>
						</label>
					</div>
				</>
			}
			{/* Show any error or success messages */}
			{errorpaymentCreate && <div id="payment-message" className="text-danger mt-3">{errorpaymentCreate}</div>}
			<div className="d-grid gap-2 mt-3">
			{
				selectedCard === "null"
				?
					<a href={payment?.challengeUrl} disabled={loadingPaymentCreate || waitingForPayment} target='_blank' className={`btn btn-primary ${loadingPaymentCreate || waitingForPayment ? 'disabled': ''}`} rel="noreferrer" onClick={handlePaymentLinkClick}>
						{
							loadingPaymentCreate || waitingForPayment
							?
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							:
								<span id="button-text">Pagar</span>
						}
					</a>
				:
					<button disabled={isProcessing} type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)}>
							<span id="button-text">
							{isProcessing ? "Procesando ... " : "Pagar"}
							</span>
					</button>
			}
			</div>
		</div>
	);
}