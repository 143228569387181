import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar'
import Swal from 'sweetalert2';
import { getNotificationsUser } from '../actions/notificationActions';
import { deletePaymentMethod, editPaymentMethod, listPaymentMethods } from '../actions/paymentMethodActions';
import { PAYMENTMETHOD_DELETE_RESET, PAYMENTMETHOD_EDIT_RESET } from '../constants/paymentMethodConstants';
import { AddPaymentMethod } from '../components/AddPaymentMethod';
import { getCardTokenUrl, getPaymentStatus } from '../actions/paymentActions';
import { PAYMENT_CHALLENGE_URL_RESET, PAYMENT_STATUS_RESET } from '../constants/paymentConstants';

export const PaymentMethodListScreen = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const closeModalRef = useRef()
	const paymentmethodList = useSelector((state) => state.paymentmethodList);
	const { loading, error, paymentMethods } = paymentmethodList;

	const paymentmethodCreate = useSelector((state) => state.paymentmethodCreate);
	const {loading: loadingCreate, success: successCreate, paymentmethod: createdPaymentMethod } = paymentmethodCreate;

	const paymentmethodEdit = useSelector((state) => state.paymentmethodEdit);
	const {loading: loadingEdit, success: successEdit } = paymentmethodEdit;
  
	const paymentmethodDelete = useSelector((state) => state.paymentmethodDelete);
	const { loading: loadingDelete, error: errorDelete, success: successDelete } = paymentmethodDelete;

	const { status } = useSelector(state => state.paymentStatus)
	const { loading: loadingPaymentCreate, error: errorpaymentCreate, payment } = useSelector(state => state.paymentCreate)

	const [addCardTimestamp, setAddCardTimestamp] = useState(null)
	const [waitingForPayment, setWaitingForPayment] = useState(false)

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
		paymentMethod: null,
		name: null
	})


	const deleteHandler = (paymentmethod) => {
		Swal.fire({
			title: `¿Desea eliminar el la tarjeta terminada en ${paymentmethod.last4} ?`,
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: 'Si',
			denyButtonText: 'No',
			cancelButtonText: "Cancelar",
			icon: "warning",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(deletePaymentMethod(paymentmethod._id));
				Swal.fire('Tarjeta eliminada', '', 'success')
			}
		})
	}

	const changeAddCardTimestamp = () => {
		setAddCardTimestamp(new Date())
	}

	const resetAddCardTimestamp = () => {
		setAddCardTimestamp(null)
	}

	const handlePaymentLinkClick = () => {
        setWaitingForPayment(true)

        const intervalFn = () => {
            clearInterval(window.addCardPaymentStatusInterval)
            setWaitingForPayment(false)
			if (closeModalRef.current) {
				closeModalRef.current.click()
			}
            dispatch({ type: 'PAYMENT_STATUS_RESET' })
        }

        window.addCardPaymentStatusIntervalIndex = 0

        window.addCardPaymentStatusInterval = setInterval(() => {
            window.addCardPaymentStatusIntervalIndex++
            dispatch(getPaymentStatus(payment.paymentReference))
            if (window.addCardPaymentStatusIntervalIndex > 600) {
                intervalFn()
            }
        }, 1000)
    }

	const handleSelectPaymentMethod = (paymentMethod) => {
		setSelectedPaymentMethod({
			paymentMethod: paymentMethod._id,
			name: paymentMethod.name
		})
	}

	const handlePaymentMethodNameChange = ({target}) => {
		setSelectedPaymentMethod({
			...selectedPaymentMethod,
			name: target.value
		})
	}

	const resetPaymentMethodSelected = () => {
		setSelectedPaymentMethod({
			paymentMethod: null,
			name: null
		})
	}

	const savePaymentMethod = () => {
		dispatch(editPaymentMethod(selectedPaymentMethod))
	}

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: PAYMENTMETHOD_DELETE_RESET });
		}
		dispatch(listPaymentMethods());
		dispatch(getNotificationsUser());
	}, [dispatch, createdPaymentMethod, navigate, successCreate, successDelete])

	useEffect(() => {
		if (addCardTimestamp) {
			dispatch(getCardTokenUrl())
		}
	}, [addCardTimestamp, dispatch])

	useEffect(() => {
		if (status?.isPaid) {
			clearInterval(window.addCardPaymentStatusInterval)
            setWaitingForPayment(false)
			dispatch(listPaymentMethods())
			dispatch({ type: PAYMENT_STATUS_RESET })
			dispatch({ type: PAYMENT_CHALLENGE_URL_RESET })
			setAddCardTimestamp(null)
			if (closeModalRef.current) {
				closeModalRef.current.click()
			}
		}
	}, [status, dispatch])

	useEffect(() => {
		if (successEdit) {
			dispatch(listPaymentMethods())
			setSelectedPaymentMethod({
				paymentMethod: null,
				name: null
			})
			dispatch({ type: PAYMENTMETHOD_EDIT_RESET })
		}
	}, [successEdit, dispatch])


	return (
		<>
			<ToolBar titulo={"Cursos"} hitos={[
				{ titulo: "Inicio", enlace: "/" },
				{ titulo: "Cursos", enlace: "#" },
			]} />
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					{loadingDelete && (<LoadingBox variant={"primary"}/>)}
					{errorDelete && (<AlertMessage variant={"danger"} message={errorDelete}/>)}
					{loadingCreate && (<LoadingBox variant={"primary"}/>)}
					{loading ? (<LoadingBox variant={"primary"} />) :
						error ? (<AlertMessage variant={"danger"} message={error} />) : (
							<div className="card card-flush">
								<div className="card-header align-items-center py-5 gap-2 gap-md-5">
									<div className="card-toolbar flex-row-fluid justify-content-end gap-5">
										<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={changeAddCardTimestamp}>
											Crear tarjeta
										</button>
									</div>
								</div>
								<div className="card-body pt-0">
									<div className='row'>
										{
											paymentMethods?.map((paymentMethod, index) => (
												<div className='col col-3' key={index}>
													<div className='card border'>
														<div className='card-header align-items-center py-5'>
															<div className='card-toolbar flex-row-fluid justify-content-between'>
																{
																	selectedPaymentMethod.paymentMethod === paymentMethod._id
																	?
																		<>
																			<div style={{width: "65%"}}>
																				<input type='text' className='form-control' value={selectedPaymentMethod.name} onChange={handlePaymentMethodNameChange}/>
																			</div>
																			<div>
																				{
																					loadingEdit
																					?
																						<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
																					:
																						<>
																							<button type='button' className='btn btn-success btn-sm p-1 me-3' onClick={savePaymentMethod}><i className='fas fa-save px-2'/></button>
																							<button type='button' className='btn btn-danger btn-sm p-1' onClick={resetPaymentMethodSelected}><i className='fas fa-times px-2'/></button>
																						</>
																				}
																			</div>
																		</>
																	:
																	<>
																		<div>
																			<span className='fw-bold'>{paymentMethod.name}</span>
																		</div>
																		<div>
																			<button type='button' className='btn btn-success btn-sm p-1 me-3' onClick={() => handleSelectPaymentMethod(paymentMethod)}><i className='bi bi-pencil-fill px-2'/></button>
																			<button type='button' className='btn btn-danger btn-sm p-1' onClick={() => deleteHandler(paymentMethod)}><i className='bi bi-trash-fill px-2'/></button>
																		</div>
																	</>
																}
															</div>
														</div>
														<div className='card-body'>
															<span className='text-muted'>**** **** **** {paymentMethod.last4}</span>
														</div>
													</div>
												</div>
											))
										}
									</div>
								</div>
							</div>
						)}
				</div>
			</div>

			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">Crear Tarjeta</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={resetAddCardTimestamp} ref={closeModalRef}/>
						</div>
						{
							process.env.REACT_APP_PAYMENT_METHOD === 'stripe' &&
							<AddPaymentMethod/>
						}
						{
							process.env.REACT_APP_PAYMENT_METHOD === 'paycomet' &&
							<div className="modal-body">
								{
									loadingPaymentCreate || waitingForPayment
									?
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									:
										payment?.challengeUrl
										?
											<div>
												<div>Para guardar su tarjeta debes realizar un pago de 0.00€ para validar la información. A continuación, le redirigiremos a la plataforma de pago seguro donde podrá insertar los datos de su tarjeta. Una vez finalizado el proceso de vinculación podrá volver a esta pantalla y verá la nueva tarjeta vinculada.</div>
												<div className='d-grid'>
													<a href={payment?.challengeUrl} target='_blank' className='btn btn-primary' rel="noreferrer" onClick={handlePaymentLinkClick}>Guardar tarjeta</a>
												</div>
											</div>
										:
											errorpaymentCreate &&
											<AlertMessage variant={"danger"} message={errorpaymentCreate}/>
								}
							</div>
						}
					</div>
				</div>
			</div>
		</>
	)
}
