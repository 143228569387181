import Axios from 'axios'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router'
import { detailsOrder } from '../actions/orderActions'
import { ToolBar } from '../components/ToolBar'
import { CART_EMPTY } from '../constants/cartConstants'

export const OrderPaidScreen = (props) => {

    const params = useParams();

    const orderId = params.id;

    const orderDetails = useSelector(state => state.orderDetails);
    const { order } = orderDetails;
    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;

    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        if (!order?._id || (order && order._id !== orderId)) {
            dispatch({ type: CART_EMPTY });
            localStorage.removeItem('cartItems');
            localStorage.removeItem('orderId');
            if (userInfo) {
                navigate('/order/' + orderId)
            }
        }
    }, [dispatch, orderId, order, navigate, userInfo]);

    useEffect(()=>{
        dispatch(detailsOrder(orderId))
    }, [dispatch, orderId])

    return (<>
        <ToolBar titulo={"Checkout"} hitos={[
            { titulo: "Checkout", enlace: "/" },
            { titulo: "Pedido finalizado", enlace: "#" }]} />
        {!userInfo &&
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <div className="row g-5">
                        <div className="col-12">
                            <div className="card h-md-100">
                                <div className="card-header pt-7" id="kt_chat_contacts_header">
                                    <div className="card-title">
                                        <h2>Pago realizado correctamente</h2>
                                    </div>
                                </div>
                                <div className="card-body d-flex flex-column">
                                    <div className="row d-flex justify-content-between">
                                        <div className="col-12">El pedido se ha pagado correctamente, ya puede cerrar esta ventana</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>)
}
