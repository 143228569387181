import Axios from 'axios';

export const getPayCometData = () => async (dispatch, getState) => {
    dispatch({ type: 'PAYCOMET_GET_DATA_REQUEST' });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/paycometData', {
            headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: 'PAYCOMET_GET_DATA_SUCCESS', payload: data });
    } catch (error) {
        dispatch({ type: 'PAYCOMET_GET_DATA_FAIL', payload: error.message });
    }
}

export const savePayCometData = (paycometData) => async (dispatch, getState) => {
    dispatch({ type: 'PAYCOMET_SAVE_DATA_REQUEST' });

    const { userSignin: { userInfo } } = getState();

    try {
        if (paycometData.dniFront && typeof paycometData.dniFront !== "string") {
            let bodyFormData = new FormData()
            bodyFormData.append('file', paycometData.dniFront)
            const dniFrontData = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3/file', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })

            paycometData.dniFront = dniFrontData.data
        }

        if (paycometData.dniBack && typeof paycometData.dniBack !== "string") {
            let bodyFormData = new FormData()
            bodyFormData.append('file', paycometData.dniBack)
            const dniBackData = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3/file', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })

            paycometData.dniBack = dniBackData.data
        }

        if (paycometData.ownershipCertificate && typeof paycometData.ownershipCertificate !== "string") {
            let bodyFormData = new FormData()
            bodyFormData.append('file', paycometData.ownershipCertificate)
            const ownershipCertificateData = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3/file', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })

            paycometData.ownershipCertificate = ownershipCertificateData.data
        }

        if (paycometData.statutesCompany && typeof paycometData.statutesCompany !== "string") {
            let bodyFormData = new FormData()
            bodyFormData.append('file', paycometData.statutesCompany)
            const statutesCompanyData = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3/file', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })

            paycometData.statutesCompany = statutesCompanyData.data
        }

        if (paycometData.cif && typeof paycometData.cif !== "string") {
            let bodyFormData = new FormData()
            bodyFormData.append('file', paycometData.cif)
            const cifData = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3/file', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })

            paycometData.cif = cifData.data
        }

        if (paycometData.paymentUptodate && typeof paycometData.paymentUptodate !== "string") {
            let bodyFormData = new FormData()
            bodyFormData.append('file', paycometData.paymentUptodate)
            const paymentUptodateData = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3/file', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })

            paycometData.paymentUptodate = paymentUptodateData.data
        }

        if (paycometData.invoiceService && typeof paycometData.invoiceService !== "string") {
            let bodyFormData = new FormData()
            bodyFormData.append('file', paycometData.invoiceService)
            const invoiceServiceData = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3/file', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })

            paycometData.invoiceService = invoiceServiceData.data
        }

        if (paycometData.corporationTax && typeof paycometData.corporationTax !== "string") {
            let bodyFormData = new FormData()
            bodyFormData.append('file', paycometData.corporationTax)
            const corporationTaxData = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3/file', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })

            paycometData.corporationTax = corporationTaxData.data
        }


        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/paycometData', paycometData, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({ type: 'PAYCOMET_SAVE_DATA_SUCCESS', payload: data.paycometData })
    } catch (error) {
        dispatch({
            type: 'PAYCOMET_SAVE_DATA_FAIL',
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const verifyPayCometData = () => async (dispatch, getState) => {
    dispatch({ type: 'PAYCOMET_SAVE_DATA_REQUEST' });

    const { userSignin: { userInfo } } = getState();

    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/paycometData/verify', {}, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({ type: 'PAYCOMET_SAVE_DATA_SUCCESS', payload: data.paycometData })
    } catch (error) {
        dispatch({
            type: 'PAYCOMET_SAVE_DATA_FAIL',
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}