import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationsUser } from '../actions/notificationActions';
import { AlertMessage } from '../components/AlertMessage';
import { CustomDataTable } from '../components/CustomDataTable';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import { cancelSubscription, listSubscriptions } from '../actions/subscriptionActions';
import Swal from 'sweetalert2';
import { SUBSCRIPTION_CANCEL_RESET } from '../constants/subscriptionConstants';
import removeAccents from '../utils/removeAccents';

export const SubscriptionListScreen = () => {
    const {loading, error, subscriptions} = useSelector(state => state.subscriptionList);

    const [subscriptionsFiltered, setSubscriptionsFiltered] = useState([])
    const [minDate, setMinDate] = useState('')
    const [maxDate, setMaxDate] = useState('')

    const {error: errorCancel, success, loading: loadingCancel} = useSelector(state => state.subscriptionCancel)

    const dispatch = useDispatch();

    const PERIODS = {"day": "Diario", "week": "Semanal", "month": "Mensual", "year": "Anual"}

    const columns = [
        {
        name: "REFERENCIA",
        selector: (row) => row.subscriptionReference,
        sortable: true,
        cell: (row) => <span>{row.subscriptionReference}</span>,
        },
        {
        name: "ID PAGO",
        selector: (row) => row.stripeId ? row.stripeId : row.payCometReference,
        sortable: true,
        cell: (row) => <span>{row.stripeId ? row.stripeId : row.payCometReference}</span>,
        },
        {
        name: "ACTIVO DESDE",
        selector: (row) => row.isPaid,
        sortable: true,          
        cell: (row) => <span>{row.isPaid ? row.paidAt?.substring(0, 10): 'No'}</span>,
        },
        {
          name: "PRODUCTO",
          selector: (row) => removeAccents(`${row.subscriptionProduct.name}, ${row.fee.name}`),
          sortable: true,
          cell: row => <span>{row.subscriptionProduct.name}, {row.fee.name}</span>
        },
        {
          name: "RECURRENCIA",
          selector: row => removeAccents(`${row.fee.price.toFixed(2)}€, ${PERIODS[row.fee.period]}`),
          sortable: true,
          cell: row => <span>{`${row.fee.price.toFixed(2)}€, ${PERIODS[row.fee.period]}`}</span>
        },
        {
          name: "CLIENTE",
          selector: row => removeAccents(row.user.name),
          sortable: true,
          cell: row => <span>{row.user.name}</span>
        },
        {
          name: "ALUMNO",
          selector: row => removeAccents(row.student?.name),
          sortable: true,
          cell: row => <span>{row.student?.name}</span>
        },
        {
        name: "",
        cell: row => 
          <div className="row">
              <div className="col-12">
                <i className="bi bi-journal-x px-2" style={{fontSize: '1.4rem', cursor: 'pointer'}} title='Cancelar suscripción' onClick={() => handleSubscriptionCancel(row.stripeId, row.alternativePayment)}/>
              </div>
          </div>,
        center: true,
      }
    ];

    const handleSubscriptionCancel = (stripeId, alternativePayment) => {
      Swal.fire({
        title: `¿Desea eliminar la suscripción?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Si',
        denyButtonText: 'No', 
        cancelButtonText: "Cancelar",
        icon: "warning",           
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(cancelSubscription(stripeId, alternativePayment))
        }
      })
    }
    useEffect(() => {
      dispatch(listSubscriptions());
      dispatch(getNotificationsUser());
    }, [dispatch])

    useEffect(() => {
      if (success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Suscripción cancelada correctamente',
          showConfirmButton: false,
          timer: 1500
        })
        dispatch({type: SUBSCRIPTION_CANCEL_RESET})
        dispatch(listSubscriptions());
      dispatch(getNotificationsUser());
      }
    }, [dispatch, success])

    useEffect(() => {
      setSubscriptionsFiltered(subscriptions)
    }, [subscriptions])

    useEffect(() => {
      let subscriptionsCopy = subscriptions
      if (minDate.trim() !== '') {
        subscriptionsCopy = subscriptionsCopy?.filter(o => new Date(o.paidAt) >= new Date(minDate))
      }
      if (maxDate.trim() !== '') {
        let dateObj = new Date(maxDate)
        subscriptionsCopy = subscriptionsCopy?.filter(o => new Date(o.paidAt) <= dateObj.setDate(dateObj.getDate() + 1))
      }
      setSubscriptionsFiltered(subscriptionsCopy || [])
    }, [minDate, maxDate, subscriptions])

    return (
    <>
    <ToolBar titulo={"Suscripciones"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Suscripciones", enlace: "#" }]}/>
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
      <div className="content flex-row-fluid" id="kt_content">        
        <div className="card card-flush">
            <div className="card-body">
              <div className='row mb-6'>
                <div className='col-6'>
                  <label className='text-muted'>Fecha de comienzo</label>
                  <input className='form-control' type="date" name='minDate' value={minDate} onChange={(e) => {setMinDate(e.target.value)}}/>
                </div>
                <div className='col-6'>
                  <label className='text-muted'>Fecha de fin</label>
                  <input className='form-control' type="date" name='maxDate' value={maxDate} onChange={(e) => {setMaxDate(e.target.value)}}/>
                </div>
              </div>
            {loadingCancel && (
                <LoadingBox variant={"primary"}/>
            )}
            {errorCancel && (
                <AlertMessage variant={"danger"} message={errorCancel}/>
            )}
            { loading ?  
                <LoadingBox variant={"primary"}/> : 
            error? 
                <AlertMessage variant={"danger"} message={error}/> : 
            (      
                <CustomDataTable data={subscriptionsFiltered} columns={columns}/>
            )
            }
            </div>
        </div>
      </div>
    </div>
    </>
    )
}
