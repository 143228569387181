import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { listPaymentMethods } from '../actions/paymentMethodActions';
import { getExtracurricularActivityInscriptionStatus, payCometExtracurricularActivityInscription } from '../actions/paymentActions';
import { PAYMENT_ACTIVITY_RESET, PAYMENT_ACTIVITY_STATUS_RESET } from '../constants/paymentConstants';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export const ExtracurricularActivityInscriptionPayCometButton = ({extracurricularActivityInscription}) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [selectedCard, setSelectedCard] = useState("null")
	const [waitingForPayment, setWaitingForPayment] = useState(false)

	const [savePaymentMethod, setSavePaymentMethod] = useState(true)

	const {paymentMethods} = useSelector(state => state.paymentmethodList)

	const { loading: loadingSubscriptionCreate, result } = useSelector(state => state.paymentActivity)
	const { status } = useSelector(state => state.activityStatus)

	const subscribe = async() => {
		setWaitingForPayment(true)
		dispatch(payCometExtracurricularActivityInscription({
			extracurricularActivityInscription: extracurricularActivityInscription?._id,
			paymentMethod: selectedCard === "null" ? null : selectedCard,
			tokenize: savePaymentMethod
		}))
	}
	useEffect(() => {
		dispatch(listPaymentMethods())
	}, [dispatch])

	useEffect(() => {
		if (paymentMethods?.length > 0) {
			setSelectedCard(paymentMethods[0]?._id)	
		}
	}, [paymentMethods])

	useEffect(() => {
		if (result) {
			const createInterval = () => {
				const intervalFn = () => {
					clearInterval(window.orderActivityStatus)
					dispatch({ type: 'PAYMENT_ACTIVITY_RESET' })
					dispatch({ type: 'PAYMENT_ACTIVITY_STATUS_RESET' })
				}
		
				window.orderActivityStatusIndex = 0
		
				window.orderActivityStatus = setInterval(() => {
					window.orderActivityStatusIndex++
					dispatch(getExtracurricularActivityInscriptionStatus(result.activity))
					if (window.orderActivityStatusIndex > 600) {
						intervalFn()
					}
				}, 1000)
			}

			createInterval()
			if (result.challengeUrl) {
				window.open(result.challengeUrl, "_blank")
			}
		}
	}, [result, dispatch])

	useEffect(() => {
		if (status?.isPaid) {
			clearInterval(window.orderActivityStatus)
			setWaitingForPayment(false)
			dispatch({ type: PAYMENT_ACTIVITY_RESET })
			dispatch({ type: PAYMENT_ACTIVITY_STATUS_RESET })
			Swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Actividad extraescolar pagada correctamente',
				showConfirmButton: false,
				timer: 1500
			})
			navigate('/extracurricularactivityhistory')
		}
	}, [status, dispatch, navigate])

	return (
		<div className='d-grid gap-2 my-7'>
		{
			paymentMethods?.length > 0 &&
			<div className='my-5'>
				<select className='form-select' name="card" value={selectedCard} onChange={(e) => setSelectedCard(e.target.value)}>
					{
						paymentMethods.map((paymentMethod, ind) => (
							<option key={ind} value={paymentMethod._id}>{paymentMethod.name || paymentMethod.last4}</option>
						))
					}
					<option value="null">Otra</option>
				</select>
			</div>
		}
		{
			selectedCard === 'null' &&
				<div className="my-5">
					<label className='form-check form-switch form-check-custom form-check-solid'>
						<input className='form-check-input' type='checkbox' checked={savePaymentMethod} onChange={e => setSavePaymentMethod(e.target.checked)} />
						<span className='form-check-label fw-bold me-4'>Guardar tarjeta</span>
					</label>
				</div>
		}
			<button disabled={waitingForPayment || loadingSubscriptionCreate} type='button' className='btn btn-primary' onClick={subscribe}>
				{
					waitingForPayment || loadingSubscriptionCreate
					?
						<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
					:
						<span id="button-text">Pagar</span>
				}
			</button>
		</div>
	)
}