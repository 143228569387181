import Axios from 'axios';
import { PAYMENTMETHOD_CREATE_FAIL, PAYMENTMETHOD_CREATE_REQUEST, PAYMENTMETHOD_CREATE_SUCCESS, PAYMENTMETHOD_DELETE_FAIL, PAYMENTMETHOD_DELETE_REQUEST, PAYMENTMETHOD_DELETE_SUCCESS, PAYMENTMETHOD_DETAILS_FAIL, PAYMENTMETHOD_DETAILS_REQUEST, PAYMENTMETHOD_DETAILS_SUCCESS, PAYMENTMETHOD_EDIT_FAIL, PAYMENTMETHOD_EDIT_REQUEST, PAYMENTMETHOD_EDIT_SUCCESS, PAYMENTMETHOD_LIST_FAIL, PAYMENTMETHOD_LIST_REQUEST, PAYMENTMETHOD_LIST_SUCCESS } from '../constants/paymentMethodConstants';

export const listPaymentMethods = () => async (dispatch, getState) => {
	dispatch({ type: PAYMENTMETHOD_LIST_REQUEST });
	const {userSignin: { userInfo }} = getState();
	try {
		const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/paymentmethod', {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			}
		});
		dispatch({ type: PAYMENTMETHOD_LIST_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: PAYMENTMETHOD_LIST_FAIL, payload: error.message });
	}
}

export const detailsPaymentMethod = (paymentmethodId) => async (dispatch) => {
	dispatch({ type: PAYMENTMETHOD_DETAILS_REQUEST, payload: paymentmethodId });

	try {
		const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/paymentmethod/${paymentmethodId}`);
		dispatch({ type: PAYMENTMETHOD_DETAILS_SUCCESS, payload: data });

	} catch (error) {
		dispatch({
			type: PAYMENTMETHOD_DETAILS_FAIL,
			payload: error.response && error.response.data.message? error.response.data.message: error.message,
		});
	}
}

export const createPaymentMethod = (paymentmethod) => async (dispatch, getState) => {
	dispatch({ type: PAYMENTMETHOD_CREATE_REQUEST });

	const { userSignin: { userInfo } } = getState();

	try {
		const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/paymentmethod', paymentmethod, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			}
		})
		dispatch({ type: PAYMENTMETHOD_CREATE_SUCCESS, payload: data.paymentmethod })
	} catch (error) {
		dispatch({
			type: PAYMENTMETHOD_CREATE_FAIL,
			payload: error.response && error.response.data.message? error.response.data.message: error.message,
		});
	}
}

export const deletePaymentMethod = (paymentmethodId) => async (dispatch, getState) => {
	dispatch({ type: PAYMENTMETHOD_DELETE_REQUEST, payload: paymentmethodId });
	const {userSignin: { userInfo }} = getState();
	try {
		await Axios.delete(process.env.REACT_APP_DB_ADDRESS + `/api/paymentmethod/${paymentmethodId}`, {
			headers: { Authorization: `Bearer ${userInfo.token}` },
		});
		dispatch({ type: PAYMENTMETHOD_DELETE_SUCCESS });
	} catch (error) {
		const message =
			error.response && error.response.data
				? error.response.data
				: error.message;
		dispatch({ type: PAYMENTMETHOD_DELETE_FAIL, payload: message });
	}
};

export const editPaymentMethod = (paymentmethod) => async (dispatch, getState) => {
	dispatch({ type: PAYMENTMETHOD_EDIT_REQUEST });

	const { userSignin: { userInfo } } = getState();

	try {
		const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/paymentmethod', paymentmethod, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			}
		})
		dispatch({ type: PAYMENTMETHOD_EDIT_SUCCESS, payload: data.paymentmethod })
	} catch (error) {
		dispatch({
			type: PAYMENTMETHOD_EDIT_FAIL,
			payload: error.response && error.response.data.message? error.response.data.message: error.message,
		});
	}
}