import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import { ToolBar } from '../components/ToolBar';
import { getPayCometData, savePayCometData, verifyPayCometData } from '../actions/paycometDataActions';

export const PayCometDataScreen = () => {
    const dispatch = useDispatch();

    const [name, setName] = useState(null);
    const [commission, setCommission] = useState(null);
    const [iban, setIban] = useState(null);
    const [sepaId, setSepaId] = useState(null);
    const [typeCompany, setTypeCompany] = useState("null");
    const [dniFront, setDniFront] = useState(null)
    const [dniBack, setDniBack] = useState(null)
    const [ownershipCertificate, setOwnershipCertificate] = useState(null)
    const [statutesCompany, setStatutesCompany] = useState(null)
    const [cif, setCif] = useState(null)
    const [paymentUptodate, setPaymentUptodate] = useState(null)
    const [invoiceService, setInvoiceService] = useState(null)
    const [corporationTax, setCorporationTax] = useState(null)
    const [ready, setReady] = useState(false);

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;

    const {loading: getLoading, paycometData} = useSelector(state => state.paycometGetData)

    const {loading: saveLoading, result} = useSelector(state => state.paycometSave)

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(savePayCometData({name, commission, iban, sepaId, typeCompany, dniFront, dniBack, ownershipCertificate, statutesCompany, cif, paymentUptodate, invoiceService, corporationTax, ready}))
    }

    const sendToVerify = () => {
        dispatch(verifyPayCometData())
    }
    
    useEffect(() => {
        if (userInfo?._id) {
            dispatch(getPayCometData())
        }
    }, [dispatch, userInfo._id])

    useEffect(() => {
        if (paycometData) {
            setName(paycometData.name)
            setCommission(paycometData.commission)
            setIban(paycometData.iban)
            setTypeCompany(paycometData.typeCompany)
            setSepaId(paycometData.sepaId)
            setReady(paycometData.ready)
        }
    }, [paycometData])

    useEffect(() => {
        if (result) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Información editada correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch({type: "PAYCOMET_SAVE_DATA_RESET"})
            dispatch(getPayCometData())
        }
    }, [result, dispatch])


    return (
        <>
            <ToolBar titulo={"Datos de PayComet"} hitos={[
                {titulo: "Inicio", enlace: "/" },
                {titulo: "Datos de PayComet", enlace: "#" }]}/>
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <div className="card mb-5 mb-xl-10">
                        <div id="kt_account_settings_profile_details" className="collapse show">
                            <form autoComplete='off' id="kt_account_profile_details_form" className="form" onSubmit={submitHandler}>
                                <div className="card-body border-top p-9">
                                    <div className="row mb-6">
                                        <div className='col-12 col-md-6'>
                                            <label className=' fw-bold fs-6'>Nombre</label>
                                            <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="name" type="text" placeholder="Razón social" value={name || ""} onChange={ (e) => setName(e.target.value)} />
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <label className=' fw-bold fs-6'>Iban</label>
                                            <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="iban" type="text" placeholder="Iban" value={iban || ""} onChange={ (e) => setIban(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row mb-6">
                                        <div className='col-12 col-md-6'>
                                            <label className=' fw-bold fs-6'>Tipo de entidad</label>
                                            <select className='form-control mb-3 mb-lg-0' value={typeCompany} onChange={ (e) => setTypeCompany(e.target.value)} id='typeCompany'>
                                                <option value="null" disabled>Seleccione una opción</option>
                                                <option value={0}>Empresa</option>
                                                <option value={1}>Autónomo</option>
                                            </select>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <label className=' fw-bold fs-6'>Comisión</label>
                                            <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="commission" type="number" placeholder="% de comisión" value={commission || ""} onChange={ (e) => setCommission(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <div className='col-12 col-md-6'>
                                            <label className=' fw-bold fs-6'>SepaId</label>
                                            <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="sepaId" type="text" placeholder="SepaId" value={sepaId || ""} onChange={ (e) => setSepaId(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <div className='col-12'>
                                            <label className='fw-bold fs-6'>Vista delantera del DNI</label>
                                            {
                                                paycometData?.dniFront && paycometData?.dniFront !== "" &&
                                                <a href={paycometData.dniFront} target='_blank' rel='noreferer' className='ms-3'><i className='fas fa-download'/></a>
                                            }
                                            <input type="file" className='form-control' onChange={(e) => setDniFront(e.target.files[0])} />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <div className='col-12'>
                                            <label className='fw-bold fs-6'>Vista trasera del DNI</label>
                                            {
                                                paycometData?.dniBack && paycometData?.dniBack !== "" &&
                                                <a href={paycometData.dniBack} target='_blank' rel='noreferer' className='ms-3'><i className='fas fa-download'/></a>
                                            }
                                            <input type="file" className='form-control' onChange={(e) => setDniBack(e.target.files[0])} />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <div className='col-12'>
                                            <label className='fw-bold fs-6'>Certificado de titularidad bancaria</label>
                                            {
                                                paycometData?.ownershipCertificate && paycometData?.ownershipCertificate !== "" &&
                                                <a href={paycometData.ownershipCertificate} target='_blank' rel='noreferer' className='ms-3'><i className='fas fa-download'/></a>
                                            }
                                            <input type="file" className='form-control' onChange={(e) => setOwnershipCertificate(e.target.files[0])} />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <div className='col-12'>
                                            <label className='fw-bold fs-6'>Escrituras de constitución</label>
                                            {
                                                paycometData?.statutesCompany && paycometData?.statutesCompany !== "" &&
                                                <a href={paycometData.statutesCompany} target='_blank' rel='noreferer' className='ms-3'><i className='fas fa-download'/></a>
                                            }
                                            <input type="file" className='form-control' onChange={(e) => setStatutesCompany(e.target.files[0])} />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <div className='col-12'>
                                            <label className='fw-bold fs-6'>CIF</label>
                                            {
                                                paycometData?.cif && paycometData?.cif !== "" &&
                                                <a href={paycometData.cif} target='_blank' rel='noreferer' className='ms-3'><i className='fas fa-download'/></a>
                                            }
                                            <input type="file" className='form-control' onChange={(e) => setCif(e.target.files[0])} />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <div className='col-12'>
                                            <label className='fw-bold fs-6'>Certificado de estar al corriente de pago</label>
                                            {
                                                paycometData?.paymentUptodate && paycometData?.paymentUptodate !== "" &&
                                                <a href={paycometData.paymentUptodate} target='_blank' rel='noreferer' className='ms-3'><i className='fas fa-download'/></a>
                                            }
                                            <input type="file" className='form-control' onChange={(e) => setPaymentUptodate(e.target.files[0])} />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <div className='col-12'>
                                            <label className='fw-bold fs-6'>Factura de los servicios prestados</label>
                                            {
                                                paycometData?.invoiceService && paycometData?.invoiceService !== "" &&
                                                <a href={paycometData.invoiceService} target='_blank' rel='noreferer' className='ms-3'><i className='fas fa-download'/></a>
                                            }
                                            <input type="file" className='form-control' onChange={(e) => setInvoiceService(e.target.files[0])} />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <div className='col-12'>
                                            <label className='fw-bold fs-6'>Declaración de IVA e impuestos de sociedades</label>
                                            {
                                                paycometData?.corporationTax && paycometData?.corporationTax !== "" &&
                                                <a href={paycometData.corporationTax} target='_blank' rel='noreferer' className='ms-3'><i className='fas fa-download'/></a>
                                            }
                                            <input type="file" className='form-control' onChange={(e) => setCorporationTax(e.target.files[0])} />
                                        </div>
                                    </div>
                                    {
                                        paycometData?.iban &&
                                        <div className='row mb-6'>
                                            <div className='col-6'>
                                                <div className='form-group mt-3'>
                                                    <label className='form-check form-switch form-check-custom form-check-solid'>
                                                        <input className='form-check-input' type='checkbox' checked={ready} onChange={(e) => setReady(e.target.checked)} />
                                                        <span className='form-check-label fw-bold text-gray-400'>Datos correctos</span>
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                paycometData && !(paycometData?.ibanVerified && paycometData?.typeCompanyVerified && paycometData?.dniFrontVerified && paycometData?.dniBackVerified && paycometData?.ownershipCertificateVerified && paycometData?.statutesCompanyVerified && paycometData?.cifVerified && paycometData?.paymentUptodateVerified && paycometData?.invoiceServiceVerified && paycometData?.corporationTaxVerified) &&
                                                <div className='col-6 text-end'>
                                                    <button type='button' className='btn btn-secondary' onClick={sendToVerify}>
                                                        {
                                                            saveLoading
                                                            ?
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            :
                                                                <span>Enviar documentos a verificación</span>
                                                        }
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className="card-footer d-flex justify-content-end py-6 px-9">
                                    <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit" disabled={saveLoading}>
                                        {
                                            saveLoading
                                            ?
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            :
                                                <span>Actualizar datos</span>
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}