import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { paySubscription } from '../actions/subscriptionActions';
import axios from 'axios';
import { listPaymentMethods } from '../actions/paymentMethodActions';
import { PAYMENTMETHOD_CREATE_RESET } from '../constants/paymentMethodConstants';
import { AddPaymentMethod } from './AddPaymentMethod';

export const SubscriptionButton = ({subscriptionProduct, fee, user, student}) => {
	const dispatch = useDispatch()
	const stripe = useStripe();
    const elements = useElements();

	const addPaymentMethodRef = useRef(null)

	const [loading, setLoading] = useState(false)

	const [selectedCard, setSelectedCard] = useState("null")

	const [savePaymentMethod, setSavePaymentMethod] = useState(true)

	const [startPurchase, setStartPurchase] = useState(false)

	const {paymentMethods} = useSelector(state => state.paymentmethodList)

	const {userInfo} = useSelector((state) => state.userSignin);

	const paymentmethodCreate = useSelector((state) => state.paymentmethodCreate);
	const { paymentmethod } = paymentmethodCreate;

	const createSubscription = async (subscriptionProduct, subscriptionFee) => {
	
		const {data} = await axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/subscriptions/', {
			subscriptionProduct: subscriptionProduct?._id,
			cancelAt: subscriptionProduct?.cancelAt,
			startAt: subscriptionProduct?.startAt,
			subscriptionFee,
			student
		}, {
			headers: {
				Authorization: `Bearer ${userInfo?.token}`,
			}
		})
		return data
	}

	const [paymentError, setPaymentError] = useState(null)

	const handleSubmit = async () => {
		if (selectedCard === 'null' && savePaymentMethod && addPaymentMethodRef.current) {
            addPaymentMethodRef.current.click()
        } else {
            setStartPurchase(true)
        }
	};

	useEffect(() => {
		dispatch(listPaymentMethods())
	}, [dispatch])

	useEffect(() => {
        if (paymentMethods?.length > 0) {
            if (paymentmethod) {
				setSelectedCard(paymentMethods[paymentMethods.length - 1]?._id)	
                setStartPurchase(true)
				dispatch({ type: PAYMENTMETHOD_CREATE_RESET});
            } else {
                setSelectedCard(paymentMethods[0]?._id || 'null')
            }
        }
    }, [paymentMethods, paymentmethod])

	useEffect(async () => {
		if (startPurchase) {
			const subscribe = async() => {
				setLoading(true)
				const {subscription, clientSecret} = await createSubscription(subscriptionProduct, fee)
		
				if (clientSecret) {
					
					if (selectedCard === 'null') {
						const cardElement = elements.getElement(CardElement)
		
						const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
							payment_method: {
								card: cardElement,
								billing_details: {
									name: user
								}
							}
						})
				
						if (paymentIntent?.status === 'succeeded') {
							dispatch(paySubscription(subscription?._id))
							setLoading(false)
						} else {
							setLoading(false)
						}
						if (error) {
							setPaymentError(error.message)
							setLoading(false)
						} else {
							setPaymentError(null)
						}
					} else {
						const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
							payment_method: subscriptionProduct?.alternativePayment ? paymentMethods.find(p => p._id === selectedCard).alternativeStripeId : paymentMethods.find(p => p._id === selectedCard).stripeId
						})
				
						if (paymentIntent?.status === 'succeeded') {
							dispatch(paySubscription(subscription?._id))
							setLoading(false)
						} else {
							setLoading(false)
						}
						if (error) {
							setPaymentError(error.message)
							setLoading(false)
						} else {
							setPaymentError(null)
						}
					}
				} else {
					dispatch(paySubscription(subscription?._id))
					setLoading(false)
				}
			}
			
			await subscribe()
		}
	}, [startPurchase])

	return (
		<div className='d-grid gap-2 my-7'>
			{
				paymentMethods?.length > 0 &&
				<div className='my-5'>
					<label className="form-label">Seleccione tarjeta</label>
					<select className='form-select' name="card" value={selectedCard} onChange={(e) => setSelectedCard(e.target.value)}>
						{
							paymentMethods.map((paymentMethod, ind) => (
								<option key={ind} value={paymentMethod._id}>{paymentMethod.name || paymentMethod.last4}</option>
							))
						}
						<option value="null">Otra</option>
					</select>
				</div>
			}
			{
				selectedCard === 'null' &&
				<>
					<div className="my-5">
						<label className='form-check form-switch form-check-custom form-check-solid'>
							<input className='form-check-input' type='checkbox' checked={savePaymentMethod} onChange={e => setSavePaymentMethod(e.target.checked)} />
							<span className='form-check-label fw-bold me-4'>Guardar tarjeta</span>
						</label>
					</div>
					{
						!savePaymentMethod
							?
								<CardElement options={{ hidePostalCode: true }} className='mb-4'/>
							:
								<AddPaymentMethod hideSubmitBtn innerRef={addPaymentMethodRef}/>
					}
				</>
			}
			<button disabled={loading || !fee || !student} type='button' className='btn btn-primary' onClick={() => handleSubmit()}>
				{
					loading
					?
						<>Espere, por favor...</>
					:
						<>Suscribirse</>
				}
			</button>
			{
				paymentError &&
				<span className='text-danger'>{paymentError}</span>
			}
		</div>
	)
}