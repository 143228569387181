import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getTeachers } from '../actions/userActions';
import { detailsCourse, updateCourse } from '../actions/courseActions';
import { getNotificationsUser } from '../actions/notificationActions';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar'
import { COURSE_UPDATE_RESET } from '../constants/courseConstants';
import Select from "react-select"

export const EditCourseScreen = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { id: courseId } = params;

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [tutor, setTutor] = useState(null)
    
    const [teachers, setTeachers] = useState([])

    const courseDetails = useSelector(state => state.courseDetails);
    const { loading, error, course } = courseDetails;

    const courseUpdate = useSelector(state => state.courseUpdate);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = courseUpdate;

    const { loading: loadingUsers, error: errorUsers, teachers: teachersFound } = useSelector(state => state.usersTeachers)

    const dispatch = useDispatch();

    useEffect(() => {
        if (successUpdate) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Curso editado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
        }
        if (!course || course._id !== courseId || successUpdate) {
            dispatch({ type: COURSE_UPDATE_RESET });

            dispatch(detailsCourse(courseId));
        }
        else {
            setName(course.name);
            setDescription(course.description);
            setTutor({label: course.tutor?.name, value: course.tutor?._id});
        }
        dispatch(getNotificationsUser());
        dispatch(getTeachers())
    }, [course, dispatch, courseId, navigate, successUpdate])

    useEffect(() => {
        setTeachers(teachersFound.map(u => ({label: u.name, value: u._id})))
    }, [teachersFound])

    const handleChange = (selectedOption) => {
        setTutor(selectedOption)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateCourse({
            _id: courseId,
            name,
            description,
            tutor: tutor?.value,
        }))
    }

    return (
        <>
            <ToolBar titulo={"Editar curso"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Cursos", enlace: "/courselist" },
                { titulo: "Editar curso", enlace: "#" },
            ]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    {loadingUpdate && (
                        <LoadingBox variant={"primary"} />
                    )}

                    {errorUpdate && (
                        <AlertMessage variant={"danger"} message={errorUpdate} />
                    )}
                    {loading ? (
                        <LoadingBox variant={"primary"} />
                    ) :
                        error ? (
                            <AlertMessage variant={"danger"} message={error} />
                        ) :
                            <>
                                <form autoComplete='off' id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" onSubmit={submitHandler}>
                                    
                                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-12">
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general">
                                                <div className="d-flex flex-column gap-7 gap-lg-10">
                                                    <div className="card card-flush py-4">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>General</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="mb-10 fv-row">
                                                                <label className="required form-label">Nombre del curso</label>
                                                                <input className="form-control mb-2" id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                                <div className="text-muted fs-7">Se requiere un nombre de curso que sea único.</div>
                                                            </div>
                                                            <div className="mb-10 fv-row">
                                                                <label className="form-label">Descripción</label>
                                                                <textarea className="form-control mb-3" rows="5" data-kt-element="input" placeholder="Descripción del curso" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                                            </div>
                                                            <div className="col-lg-12 fv-row">
                                                                <label className="form-label">Tutor</label>
                                                                {loadingUsers ? (<LoadingBox variant={"primary"} />) :
                                                                    errorUsers ? (<AlertMessage variant={"danger"} message={errorUsers} />) : (
                                                                        <Select isClearable options={teachers} onChange={handleChange} value={tutor} placeholder="Escoja el tutor del curso" />
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>        
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <Link to="/courselist" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">Cancelar</Link>
                                            <button type="submit" id="kt_ecommerce_add_product_submit" className="btn btn-primary">
                                                <span className="indicator-label">Actualizar</span>
                                                <span className="indicator-progress">Espere por favor...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </>
                    }
                </div>
            </div>
        </>
    )
}
