import { ORDERS_IMPORT_FAIL, ORDERS_IMPORT_REQUEST, ORDERS_IMPORT_SUCCESS, ORDER_CHANGE_SAVE_CARD_ERROR, ORDER_CHANGE_SAVE_CARD_REQUEST, ORDER_CHANGE_SAVE_CARD_RESET, ORDER_CHANGE_SAVE_CARD_SUCCESS, ORDER_CHANGE_TO_PAID_FAIL, ORDER_CHANGE_TO_PAID_REQUEST, ORDER_CHANGE_TO_PAID_RESET, ORDER_CHANGE_TO_PAID_SUCCESS, ORDER_CREATE_FAIL, ORDER_CREATE_REQUEST, ORDER_CREATE_RESET, ORDER_CREATE_SUCCESS, ORDER_DELETE_FAIL, ORDER_DELETE_REQUEST, ORDER_DELETE_RESET, ORDER_DELETE_SUCCESS, ORDER_DELIVER_FAIL, ORDER_DELIVER_REQUEST, ORDER_DELIVER_RESET, ORDER_DELIVER_SUCCESS, ORDER_DETAILS_FAIL, ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_EDIT_FAIL, ORDER_EDIT_REQUEST, ORDER_EDIT_RESET, ORDER_EDIT_SUCCESS, ORDER_FIND_BY_CUSTOM_FILTER_ERROR, ORDER_FIND_BY_CUSTOM_FILTER_REQUEST, ORDER_FIND_BY_CUSTOM_FILTER_SUCCESS, ORDER_HIDE_ERROR, ORDER_HIDE_REQUEST, ORDER_HIDE_RESET, ORDER_HIDE_SUCCESS, ORDER_IMPORT_RESET, ORDER_LIST_FAIL, ORDER_LIST_NOT_PAID_FAIL, ORDER_LIST_NOT_PAID_REQUEST, ORDER_LIST_NOT_PAID_RESET, ORDER_LIST_NOT_PAID_SUCCESS, ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS, ORDER_MINE_LIST_FAIL, ORDER_MINE_LIST_REQUEST, ORDER_MINE_LIST_SUCCESS, ORDER_PAID_BY_DATE_FAIL, ORDER_PAID_BY_DATE_REQUEST, ORDER_PAID_BY_DATE_RESET, ORDER_PAID_BY_DATE_SUCCESS, ORDER_PAY_FAIL, ORDER_PAY_REQUEST, ORDER_PAY_RESET, ORDER_PAY_SUCCESS, ORDER_SALES_INFO_REQUEST, ORDER_SALES_INFO_SUCCESS, ORDER_SALES_PRODUCT_REQUEST, ORDER_SALES_PRODUCT_SUCCESS } from "../constants/orderConstants";

export const orderCreateReducer = (state = {loading: false, success: false, order: null, error: null}, action) => {
    switch (action.type) {
        case ORDER_CREATE_REQUEST:
            return { ...state, loading: true }
        case ORDER_CREATE_SUCCESS:
            return { ...state, loading: false, success: true, order: action.payload };
        case ORDER_CREATE_FAIL:
            return { ...state, loading: false, error: action.payload };
        case ORDER_CREATE_RESET:
            return {...state, loading: false, success: false, order: null, error: null};
        default:
            return state;
    }
}

export const orderDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case ORDER_DETAILS_REQUEST:
            return { loading: true };
        case ORDER_DETAILS_SUCCESS:
            return { loading: false, order: action.payload };
        case ORDER_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};


export const orderPayReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_PAY_REQUEST:
            return { loading: true }
        case ORDER_PAY_SUCCESS:
            return { loading: false, success: true }
        case ORDER_PAY_FAIL:
            return { loading: true, error: action.payload }
        case ORDER_PAY_RESET:
            return {}
        default:
            return state;
    }
}

export const orderMineListReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case ORDER_MINE_LIST_REQUEST:
            return { loading: true };
        case ORDER_MINE_LIST_SUCCESS:
            return { loading: false, orders: action.payload };
        case ORDER_MINE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const orderListReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case ORDER_LIST_REQUEST:
            return { loading: true };
        case ORDER_LIST_SUCCESS:
            return { loading: false, orders: action.payload };
        case ORDER_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const orderDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_DELETE_REQUEST:
            return { ...state, loading: true };
        case ORDER_DELETE_SUCCESS:
            return { ...state, loading: false, success: true };
        case ORDER_DELETE_FAIL:
            return { ...state, loading: false, error: action.payload };
        case ORDER_DELETE_RESET:
            return {};
        default:
            return state;
    }
}


export const orderDeliverReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_DELIVER_REQUEST:
            return { loading: true }
        case ORDER_DELIVER_SUCCESS:
            return { loading: false, success: true }
        case ORDER_DELIVER_FAIL:
            return { loading: true, error: action.payload }
        case ORDER_DELIVER_RESET:
            return {}
        default:
            return state;
    }
}

export const orderSalesInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_SALES_INFO_REQUEST:
            return {loading: true}
        case ORDER_SALES_INFO_SUCCESS:
                return {loading: false, sales: action.payload}
        default:
            return state;
    }
}

export const orderSalesProductReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_SALES_PRODUCT_REQUEST:
            return {loading: true}
        case ORDER_SALES_PRODUCT_SUCCESS:
                return {loading: false, sales: action.payload}
        default:
            return state;
    }
}

export const orderEditReducer = (state = {loading: false, success: false, order: null, error: null}, action) => {
    switch (action.type) {
        case ORDER_EDIT_REQUEST:
            return { ...state, loading: true }
        case ORDER_EDIT_SUCCESS:
            return { ...state, loading: false, success: true, order: action.payload };
        case ORDER_EDIT_FAIL:
            return { ...state, loading: false, error: action.payload };
        case ORDER_EDIT_RESET:
            return {...state, loading: false, success: false, order: null, error: null};
        default:
            return state;
    }
}

export const ordersImportReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDERS_IMPORT_REQUEST:
            return { loading: true };
        case ORDERS_IMPORT_SUCCESS:
            return { loading: false, success: true };
        case ORDERS_IMPORT_FAIL:
            return { loading: false, error: action.payload };
        case ORDER_IMPORT_RESET:
            return {};
        default:
            return state;
    }
}
export const ordersPaidByDateReducer = (state = {loading: false, orders: []}, action) => {
    switch (action.type) {
        case ORDER_PAID_BY_DATE_REQUEST:
            return {...state, loading: true };
        case ORDER_PAID_BY_DATE_SUCCESS:
            return {...state, loading: false, orders: action.payload };
        case ORDER_PAID_BY_DATE_FAIL:
            return {...state, loading: false, error: action.payload };
        case ORDER_PAID_BY_DATE_RESET:
            return {...state, loading: false, orders: []};
        default:
            return state;
    }
}

export const ordersChangeToPaidReducer = (state = {loading: false, success: null}, action) => {
    switch (action.type) {
        case ORDER_CHANGE_TO_PAID_REQUEST:
            return { loading: true };
        case ORDER_CHANGE_TO_PAID_SUCCESS:
            return { loading: false, success: true };
        case ORDER_CHANGE_TO_PAID_FAIL:
            return { loading: false, success: false };
        case ORDER_CHANGE_TO_PAID_RESET:
            return {loading: false, success: null};
        default:
            return state;
    }
}

export const orderListNotPaidReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case ORDER_LIST_NOT_PAID_REQUEST:
            return { loading: true };
        case ORDER_LIST_NOT_PAID_SUCCESS:
            return { loading: false, orders: action.payload };
        case ORDER_LIST_NOT_PAID_FAIL:
            return { loading: false, error: action.payload };
        case ORDER_LIST_NOT_PAID_RESET:
            return {loading: false, error: null}
        default:
            return state;
    }
}

export const orderHideReducer = (state = {orders: [], error: null, loadingList: false, result: null, loading: false}, action) => {
    switch (action.type) {
        case ORDER_FIND_BY_CUSTOM_FILTER_REQUEST:
            return {...state, loadingList: true, error: null}
        case ORDER_FIND_BY_CUSTOM_FILTER_ERROR:
            return {...state, loadingList: false, error: action.payload}
        case ORDER_FIND_BY_CUSTOM_FILTER_SUCCESS:
            return {...state, loadingList: false, orders: action.payload, error: null}
        case ORDER_HIDE_REQUEST:
            return {...state, loading: true}
        case ORDER_HIDE_ERROR:
            return {...state, loading: false, result: 'error'}
        case ORDER_HIDE_SUCCESS:
            return {...state, loading: false, result: 'success'}
        case ORDER_HIDE_RESET:
            return {...state, result: null}
        default:
            return state;
    }
}

export const orderSaveCardReducer = (state = { loading: false, result: null }, action) => {
    switch (action.type) {
        case ORDER_CHANGE_SAVE_CARD_REQUEST:
            return { loading: true };
        case ORDER_CHANGE_SAVE_CARD_SUCCESS:
            return { loading: false, result: action.payload };
        case ORDER_CHANGE_SAVE_CARD_ERROR:
            return { loading: false };
        case ORDER_CHANGE_SAVE_CARD_RESET:
            return {loading: false, result: null}
        default:
            return state;
    }
}